import React, { useEffect, useState } from 'react';
import { api, apiToken } from '../../config';
import { prepareDataForPeople } from '../../utility';
import './scannedTicket.scss';

const ScannedTicket = ({ data, setData }) => {
  const [ticket, setTicket] = useState(null);
  const [ticketPeople, setTicketPeople] = useState(null);
  const fetchTicketDetails = async () => {
    const ticketData = await fetch(`${api}/collections/get/tickets`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        filter: {
          _id: data,
        },
      }),
    });
    const ticketJson = await ticketData.json();
    if (ticketJson.entries) {
      setTicket(ticketJson.entries[0]);
      const peopleArray = prepareDataForPeople(ticketJson.entries[0]);
      setTicketPeople(peopleArray);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, [data]);

  const handleSaveTicket = async () => {
    const pSelectedObj = {};
    ticketPeople
      .filter((item) => item.selection === true)
      .map((item) => (pSelectedObj[item.statusKey] = true));
    const dataToPost = {
      _id: data,
      verificationStatus: true,
      ...pSelectedObj,
    };
    console.log(dataToPost);
    const ticketData = await fetch(`${api}/collections/save/tickets`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        data: dataToPost,
      }),
    });
    await ticketData.json();
    setData(null);
  };

  const PersonRow = ({ name, type, status, arrIndex, selection }) => {
    const handlePeopleSelection = ({ name, type, status, arrIndex }) => {
      const newArr = [...ticketPeople];
      newArr[arrIndex] = { ...ticketPeople[arrIndex], selection: !selection };
      setTicketPeople(newArr);
    };
    return (
      <tr
        className='personRow'
        style={{
          backgroundColor:
            status === true ? '#dfffe5' : selection ? '#ddd' : 'transparent',
        }}
        onClick={(e) => handlePeopleSelection({ name, type, status, arrIndex })}
      >
        <td className='smaller'>{type}</td>
        <td className='smaller'>{name}</td>
      </tr>
    );
  };

  return (
    <>
      {ticket && (
        <div className='scannedTicket'>
          <div className='holes-top'></div>
          <div className='title'>
            <p className='cinema'>{ticket.membership}</p>
            <p className='movie-title'>{ticket.title}</p>
          </div>
          <div className='info'>
            <table>
              <tbody>
                <tr>
                  <th>PRICE</th>
                  <th>STATUS</th>
                </tr>
                <tr>
                  <td>£{ticket.ticketPrice}</td>
                  <td
                    className={ticket.verificationStatus ? 'used' : 'not-used'}
                  >
                    {ticket.verificationStatus ? 'USED TICKET' : 'FIRST SCAN'}
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                {ticketPeople &&
                  ticketPeople.map((person, arrIndex) => (
                    <PersonRow
                      name={person.name}
                      status={person.status}
                      key={person.key}
                      type={person.type}
                      arrIndex={arrIndex}
                      selection={person.selection ? person.selection : false}
                    />
                  ))}
              </tbody>
            </table>
          </div>
          <div className='holes-lower'></div>
          <div className='serial'>
            <button className='btn btn-primary' onClick={handleSaveTicket}>
              SAVE
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ScannedTicket;
