import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import EventCalendar from '../../components/eventCalendar';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const EventPage = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={'Event Calendar'} pagesub={'Events'} />
      <EventCalendar />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default EventPage;
