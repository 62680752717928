import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import GalleryImage from './GalleryImage';
import Loading from '../Loading';
import './style.css';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken, imagePath } from '../../config';

const Gallery = (props) => {
  const history = useHistory();
  const [store, setStore] = useContext(AppContext);
  const [images, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const getImageData = async (image) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () =>
        resolve({
          path: `${imagePath}/${image.path}`,
          width: img.width,
          height: img.height,
        });
      img.onerror = reject;
      img.src = `${imagePath}/${image.path}`;
    });
  };

  const prepareImageData = async (galleryImages) => {
    setLoading(true);
    const imgData = await Promise.all(
      galleryImages.map(async (image) => {
        let itemRes = await getImageData(image);
        return itemRes;
      })
    );
    setImage(imgData);
    setLoading(false);
  };

  useEffect(() => {
    if (store.galleryImages) {
      prepareImageData(store.galleryImages);
    } else {
      history.push('/gallery');
    }
  }, [store.galleryImages]);
  const galleryId = props.match.params.id;
  return (
    <div className='gallery-page section-padding v2'>
      <div className='fluid-container'>
        <div className='row'>
          <div className='col col-12'>
            <div
              style={{
                display: 'flex',
                width: '100%',
                'justify-content': 'center',
                marginBottom: '30px',
              }}
            >
              <Link
                to='/gallery'
                className='theme-btn'
                style={{ padding: '12px 60px 12px 30px' }}
              >
                <span className='post-control-link'>Back</span>
                <i
                  className='fa fa-angle-double-left'
                  aria-hidden='true'
                  style={{ display: 'block' }}
                ></i>
              </Link>
            </div>
          </div>
          {loading && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                'justify-content': 'center',
              }}
            >
              <Loading />
            </div>
          )}
          {images && <GalleryImage images={images} galleryID={galleryId} />}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
