import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { api, apiToken } from '../../config';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    lastname: '',
    events: '',
    notes: '',
    error: {},
  };
  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = '';

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    let noError = false;
    const { name, email, subject, lastname, events, notes, error } = this.state;

    if (name === '') {
      error.name = 'Please enter your name';
    }
    if (email === '') {
      error.email = 'Please enter your email';
    }
    if (subject === '') {
      error.subject = 'Please enter your subject';
    }
    if (lastname === '') {
      error.lastname = 'Please enter your Lastname';
    }
    if (notes === '') {
      error.notes = 'Please enter your note';
    }
    if (error) {
      this.setState({
        error,
      });
    }
    if (
      error.name === '' &&
      error.email === '' &&
      error.lastname === '' &&
      error.subject === '' &&
      error.notes === ''
    ) {
      this.setState({
        error: {},
      });
      noError = true;
    }
    if (noError) {
      // send email
      fetch(`https://cockpit.mmaonline.org.uk/email/send-email.php`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            subject: `MMA Contact Form: ${subject}`,
            recipient: 'info@mmaonline.org.uk',
            body: `From <br/>
              First Name: ${name} <br/>
              Last Name: ${lastname} <br/>
              Email: ${email} <br/>
              Subject: ${subject} <br/>
              Message: ${notes} <br/>
              `,
          },
        }),
      });

      // post form data
      fetch(`${api}/forms/submit/contactus`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Cockpit-Token': apiToken,
        },
        body: JSON.stringify({
          form: {
            name,
            lastname,
            email,
            subject,
            notes,
          },
        }),
      })
        .then((entry) => entry.json())
        .then((entry) => {
          toast.success(
            'Thank you for contacting us. We will get back you soon.'
          );
          this.setState({
            name: '',
            lastname: '',
            email: '',
            subject: '',
            notes: '',
            error: {},
          });
          console.log(entry);
        });
    }
  };

  render() {
    const { name, email, subject, lastname, error, notes } = this.state;

    return (
      <form onSubmit={this.submitHandler} className='form'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='form-field'>
              <input
                value={name}
                onChange={this.changeHandler}
                type='text'
                name='name'
                placeholder='Name'
              />
              <p>{error.name ? error.name : ''}</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='form-field'>
              <input
                value={lastname}
                onChange={this.changeHandler}
                type='text'
                name='lastname'
                placeholder='Lastname'
              />
              <p>{error.lastname ? error.lastname : ''}</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='form-field'>
              <input
                onChange={this.changeHandler}
                value={email}
                type='email'
                name='email'
                placeholder='Email'
              />
              <p>{error.email ? error.email : ''}</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='form-field'>
              <input
                onChange={this.changeHandler}
                value={subject}
                type='text'
                name='subject'
                placeholder='Subject'
              />
              <p>{error.subject ? error.subject : ''}</p>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='form-field'>
              <textarea
                name='notes'
                placeholder='Message'
                value={notes}
                onChange={this.changeHandler}
              ></textarea>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='form-submit'>
              <button type='submit' className='theme-btn'>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default ContactForm;
