import React, { Fragment, useEffect, useContext } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Features from '../../components/Features';
import About2 from '../../components/about2';
import TeamSection from '../../components/team';
import Testimonial from '../../components/Testimonial';
import CounterSection from '../../components/counter';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken } from '../../config';

const SchoolPage = () => {
  const [store, setStore] = useContext(AppContext);

  const getAboutUs = async () => {
    const dataRes = await fetch(`${api}/singletons/get/aboutSchool`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: 1 },
      }),
    });
    const aboutUs = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      content: { ...aboutUs },
    }));
  };

  useEffect(() => {
    if (!store.content) {
      getAboutUs();
    }
  }, [store.content]);

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={'School'} pagesub={'School'} />
      {store.content && <About2 content={store.content} />}
      {/* <Testimonial /> */}
      {/* <CounterSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default SchoolPage;
