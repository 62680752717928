import React, { useEffect, useContext } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken, imagePath } from '../../config';

const GallerySection = (props) => {
  const ClickHandler = (title, images) => {
    window.scrollTo(10, 0);
    setStore((prevStore) => ({
      ...prevStore,
      galleryImages: images,
      galleryTitle: title,
    }));
  };

  const [store, setStore] = useContext(AppContext);

  const getGalleryData = async () => {
    const dataRes = await fetch(`${api}/collections/get/gallery`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { order: 1 },
      }),
    });
    const galleryData = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      gallery: [...galleryData.entries],
    }));
  };

  useEffect(() => {
    if (!store.gallery) {
      getGalleryData();
    }
  }, [store.gallery]);

  return (
    <div className={`volunteer-area section-padding ${props.addclass}`}>
      <div className='container'>
        <div className='volunteer-wrap'>
          <div className='row'>
            {store.gallery &&
              store.gallery.map((item) => (
                <div className='col col-lg-4 col-md-6 custom-grid col-12'>
                  <div className='volunteer-item'>
                    <Link
                      onClick={() => ClickHandler(item.title, item.images)}
                      to={`/gallery/${item._id}`}
                    >
                      <div className='volunteer-img'>
                        <img
                          src={`${imagePath}/${item.featuredImage.path}`}
                          alt=''
                        />
                      </div>
                      <div
                        className='volunteer-content'
                        style={{ marginTop: '0px' }}
                      >
                        <h3>{item.title}</h3>
                        <span>{item.images.length} photos</span>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection;
