import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Redirect } from "react-router-dom";
import AdminMenu from "./AdminMenu";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { api, apiToken } from "../../config";

import "./style.css";
import WomensDayTicketWrapper from "./WomensDayTicketWrapper";

const WomensDayTicketList = (props) => {
  const [store, setStore] = useContext(AppContext);
  const [tickets, setTickets] = useState(null);
  const { auth } = store;
  const initialState = {
    event: "",
    year: new Date().getFullYear(),
  };
  const [value, setValue] = useState(initialState);

  const changeHandler = (e) => {
    setValue({ ...value, event: "", year: e.target.value });
  };

  const getTicketList = async () => {
    const data = await fetch(`${api}/collections/get/womensday`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        sort: { transactionTitle: -1 },
        filter: {
          year: value.year,
          paymentStatus: true,
          delete: false,
        },
      }),
    });

    const ticketData = await data.json();
    const ticketByTitle = {
      entries: [],
      adultsCount: 0,
      childrenCount: 0,
      totalPrice: 0,
    };
    ticketData.entries.forEach((element) => {
      ticketByTitle.entries.push(element);
      ticketByTitle.adultsCount += 1;
      ticketByTitle.childrenCount += parseFloat(element.numberOfChildren);
      ticketByTitle.totalPrice += parseFloat(element.price);
    });

    setTickets(ticketByTitle);
  };

  const totalCount = (item) => {
    const totalCnt = item.adultsCount + item.childrenCount;
    return totalCnt;
  };

  useEffect(() => {
    getTicketList();
  }, [value.year]);

  return (
    <div className="tp-case-details-area section-padding">
      <div className="container-fluid">
        <div className="row">
          {auth && (
            <div className="col col-12">
              {auth.role === "accountant" ? (
                <div className="tp-blog-sidebar">
                  <AdminMenu />
                  <br />
                  <br />
                  <div className="widget search-widget">
                    <div className="row">
                      <div className="col col-6 col-md-6">
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="year-label">year</InputLabel>
                          <Select
                            className="inputOutline"
                            name="year"
                            value={value[`year`]}
                            id="year-label"
                            label="year"
                            placeholder="year"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                          >
                            <MenuItem value={new Date().getFullYear()}>
                              {new Date().getFullYear()}
                            </MenuItem>
                            <MenuItem value={new Date().getFullYear() - 1}>
                              {new Date().getFullYear() - 1}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row peopleCountWrapper">
                      <div className="col col-12 col-md-12">
                        <div className="pCountItem pink">
                          <div>
                            <h4>{tickets && tickets.adultsCount}</h4>
                            <span>Adults</span>
                          </div>
                        </div>
                        <div className="pCountItem">
                          <div>
                            <h4>{tickets && tickets.childrenCount}</h4>
                            <span>Children</span>
                          </div>
                        </div>

                        <div className="pCountItem teal">
                          <div>
                            <h4>{tickets && totalCount(tickets)}</h4>
                            <span>Total Count</span>
                          </div>
                        </div>
                        <div className="pCountItem orange">
                          <div>
                            <h4>
                              £{` `}
                              {tickets && tickets.totalPrice}
                            </h4>
                            <span>Total Amount</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {tickets && (
                    <WomensDayTicketWrapper search={value} tickets={tickets} />
                  )}
                </div>
              ) : (
                <Redirect to="/mem-account" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WomensDayTicketList;
