import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api, apiToken } from '../../config';
import './style.scss';

const PayCancel = (props) => {
  const transactionId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const updateStatus = async () => {
    const transaction = await fetch(
      `${api}/collections/save/membertransactions`,
      {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            _id: transactionId,
            status: 'failed',
          },
        }),
      }
    );
    const transactionSaved = await transaction.json();
    setLoading(false);
  };

  useEffect(() => {
    updateStatus();
  }, []);
  return (
    <div className='wrapper error-page'>
      <h2>Payment Failed</h2>
      <p>
        Your payment is failed or canceled. You can see all payment history
        under My Account page. Please contact MMA if you are facing issues.
      </p>
      <Link to='/mem-account' className='btn' disabled={loading}>
        {loading ? 'Loading...' : 'Done'}
      </Link>
    </div>
  );
};

export default PayCancel;
