import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken, imagePath } from '../../config';

const DocumentList = (props) => {
  const [store, setStore] = useContext(AppContext);

  const getDocuments = async () => {
    const dataRes = await fetch(`${api}/collections/get/documents`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
      }),
    });
    const docList = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      documents: [...docList.entries],
    }));
  };

  useEffect(() => {
    if (!store.documents) {
      getDocuments();
    }
  }, [store.documents]);

  return (
    <div className='event-area section-padding event-calendar'>
      <div className='container'>
        <div className='row'>
          {store.documents &&
            store.documents.map((doc) => (
              <div className='col-lg-6'>
                <div className='event-item'>
                  <div className='event-text'>
                    <div className='event-left' style={{ minWidth: '75px' }}>
                      <div className='event-l-text'>
                        <h4 style={{ margin: 0 }}>
                          <i className='fa fa-file-text' aria-hidden='true'></i>
                        </h4>
                      </div>
                    </div>
                    <div className='event-right'>
                      <a href={`${imagePath}/${doc.document}`} target='_blank'>
                        <h2 style={{ margin: 0 }}>{doc.title}</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentList;
