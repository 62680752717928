import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { AppContext } from '../../contexts/AppContext';
import { imagePath } from '../../config';

const BlogSingle = ({ match }) => {
  const id = match.params.id;
  const [store, setStore] = useContext(AppContext);
  const [news, setNews] = useState(null);

  useEffect(() => {
    if (store.news) {
      const data = store.news.filter((item) => item._id === id);
      console.log(data[0]);
      setNews(data[0]);
    }
  }, [store.news]);

  const timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate() + 1;
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year;
    return time;
  };

  return (
    <section className='tp-blog-single-section  section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-12 col-12'>
            {news && (
              <div className='tp-tp-blog-content clearfix'>
                <div className='post'>
                  <div className='entry-media'>
                    <img
                      src={`${imagePath}/storage/uploads${news.image.path}`}
                      alt=''
                    />
                  </div>
                  <ul className='entry-meta'>
                    <li>
                      <Link to='/blog-details'>
                        <i className='ti-calendar'></i>{' '}
                        {timeConverter(news._created)}
                      </Link>
                    </li>
                  </ul>
                  <h2>{news.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: news.content }} />
                </div>
                <div className='more-posts clearfix'>
                  <div className='previous-post'>
                    <Link to='/blog'>
                      <span className='post-control-link'>Back</span>
                    </Link>
                  </div>
                  {/* <div className='next-post'>
                    <Link to='/blog-details'>
                      <span className='post-control-link'>Next post</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
