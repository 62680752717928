import React from 'react';

const Logout = () => {
  localStorage.removeItem('auth');
  localStorage.removeItem('email');
  window.location.href = window.location.origin;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      Logging out! Please wait...
    </div>
  );
};

export default Logout;
