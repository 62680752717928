import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import 'photoswipe/dist/photoswipe.css';
import './gallery-style.css';

const GalleryImage = (props) => {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#my-test-gallery',
      children: 'a',
      pswpModule: () => import('photoswipe/dist/photoswipe.esm'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);
  return (
    <div className='pswp-gallery' id='my-test-gallery'>
      {props.images.map((image, index) => (
        <a
          href={image.path}
          data-pswp-width={image.width}
          data-pswp-height={image.height}
          key={'my-test-gallery' + '-' + index}
          target='_blank'
          rel='noreferrer'
        >
          <img src={image.path} alt='' style={{ width: '350px' }} />
        </a>
      ))}
    </div>
  );
};

export default GalleryImage;
