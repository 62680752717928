import React, { useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";
import { api, apiToken, privateKey } from "../../config";

import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";

import { AppContext } from "../../contexts/AppContext";

import "./style.scss";

const LoginPage = (props) => {
  const [store, setStore] = useContext(AppContext);
  const [value, setValue] = useState({
    email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
    password: "",
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const submitForm = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setValue({
        email: "",
        password: "",
      });
      validator.hideMessages();

      const username = value.email;

      if (username && username !== "") {
        const hashDigest = sha256(value.password);
        const hmacDigest = Base64.stringify(hmacSHA512(hashDigest, privateKey));
        const uData = await fetch(`${api}/collections/get/userlist`, {
          headers: {
            "Cockpit-Token": apiToken,
            "Content-Type": "application/json",
          },
          method: "post",
          body: JSON.stringify({
            sort: { _created: -1 },
            filter: {
              email: value.email.toLowerCase(),
              password: hmacDigest,
              delete: false,
            },
            fields: {
              email: 1,
              fullName: 1,
              status: 1,
              paymentStatus: 1,
              role: 1,
              memberId: 1,
              membership: 1,
              lastSubscriptionRenewedYear: 1,
              _id: 1,
            },
          }),
        });
        const user = await uData.json();
        if (user.entries.length === 0) {
          validator.showMessages();
          toast.error(
            "Username or password incorrect, please contact MMA for help."
          );
        } else {
          if (user.entries[0].status === true) {
            toast.success("You successfully Login on MMA !");
            localStorage.setItem("email", user.entries[0].email);

            localStorage.setItem("auth", JSON.stringify(user.entries[0]));
            setStore((prevStore) => ({
              ...prevStore,
              auth: {
                ...user.entries[0],
              },
            }));
            const redirectPath = localStorage.getItem("redirectAfterLogin");
            localStorage.removeItem("redirectAfterLogin");
            props.history.push(redirectPath || "/mem-account");
          } else {
            validator.showMessages();
            toast.error("Your account is under review. Please contact us.");
          }
        }
      }
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };
  return (
    <Grid className="loginWrapper">
      <Grid className="loginForm">
        <h2>Sign In</h2>
        <p>Sign in to your account</p>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="E-mail"
                value={value.email}
                variant="outlined"
                name="email"
                label="E-mail"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("email", value.email, "required|email")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Password"
                value={value.password}
                variant="outlined"
                name="password"
                type="password"
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("password", value.password, "required")}
            </Grid>
            <Grid item xs={12} className="formAction">
              <Link to="/forgot-password">Forgot Password?</Link>
            </Grid>
            <Grid item xs={12}>
              <Grid className="formFooter">
                <Button fullWidth className="cBtnTheme" type="submit">
                  Login
                </Button>
              </Grid>
              <p className="noteHelp">
                Don't have an account? <Link to="/signup">Create account</Link>
                <br />
                <Link to="/">Go Back</Link>
              </p>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default withRouter(LoginPage);
