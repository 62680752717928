import React, { useContext } from 'react';
import Logo from '../../images/logo/logo.png';
import { Link, useLocation } from 'react-router-dom';
import HeaderTopbar from '../HeaderTopbar';
import MobileMenu from '../../components/MobileMenu';
import { AppContext } from '../../contexts/AppContext';
import './style.css';

const Header = () => {
  const location = useLocation();
  const [store, setStore] = useContext(AppContext);

  return (
    <div className='middle-header'>
      <HeaderTopbar auth={store.auth} siteSettings={store.siteSettings} />
      <div className='header-style-3'>
        <div className='container'>
          <div className='header-content'>
            <div className='row'>
              <div className='col-lg-3 col-md-4 col-sm-4 col-4'>
                <div className='logo'>
                  <Link to='/' title=''>
                    <img src={Logo} alt='' />
                  </Link>
                </div>
              </div>
              <div className='col-lg-9 d-lg-block d-none'>
                <nav>
                  <ul>
                    <li>
                      {store.auth &&
                      store.auth.status &&
                      store.auth.role === 'accountant' ? (
                        <>
                          <Link
                            className={
                              location.pathname == '/contact' ? 'active' : ''
                            }
                            to='/accounts'
                            title=''
                          >
                            Dashboard
                          </Link>
                          <Link
                            className={
                              location.pathname == '/documents' ? 'active' : ''
                            }
                            to='/documents'
                            title=''
                          >
                            Documents
                          </Link>
                        </>
                      ) : store.auth && store.auth.status ? (
                        <Link
                          className={
                            location.pathname == '/documents' ? 'active' : ''
                          }
                          to='/documents'
                          title=''
                        >
                          Documents
                        </Link>
                      ) : (
                        ''
                      )}
                    </li>
                    <li>
                      <Link
                        className={
                          location.pathname == '/about' ? 'active' : ''
                        }
                        to='/about'
                        title=''
                      >
                        About Us
                      </Link>
                    </li><li>
                      <Link
                        className={
                          location.pathname == '/school' ? 'active' : ''
                        }
                        to='/school'
                        title=''
                      >
                        School
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          location.pathname == '/office-bearers' ? 'active' : ''
                        }
                        to='/office-bearers'
                        title=''
                      >
                        Office Bearers
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          location.pathname == '/event' ? 'active' : ''
                        }
                        to='/event'
                        title=''
                      >
                        Event Calender
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          location.pathname == '/gallery' ? 'active' : ''
                        }
                        to='/gallery'
                        title=''
                      >
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={location.pathname == '/blog' ? 'active' : ''}
                        to='/blog'
                        title=''
                      >
                        News
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          location.pathname == '/contact' ? 'active' : ''
                        }
                        to='/contact'
                        title=''
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <MobileMenu auth={store.auth} name='saj' />
            </div>

            <div className='clearfix'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
