import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { AppContext } from '../../contexts/AppContext';
import { api, apiToken } from '../../config';
import logo from '../../images/logo/logo.png';
import './style.scss';

import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};

const RegPay = (props) => {
  const memId = props.match.params.id;
  const [store, setStore] = useContext(AppContext);
  const { siteSettings } = store;
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({
    regFee: null,
    memFee: null,
    total: null,
    productId: null,
  });
  const [userDetails, setUserDetails] = useState(null);

  const getMember = async (id) => {
    const memListData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { _id: memId, delete: false },
        fields: {
          fullName: 1,
          postcode: 1,
          paymentStatus: 1,
          email: 1,
          membership: 1,
        },
      }),
    });
    const members = await memListData.json();
    if (members.entries.length > 0) {
      setUserDetails(members.entries[0]);
      const thisMonth = new Date().getMonth() + 1;
      if (members.entries[0].membership === 'Family') {
        const subscriptionFee =
          thisMonth > parseInt(siteSettings.freeSubscriptionAfterMonth)
            ? 0
            : parseInt(siteSettings.familyMembershipRenewFee);
        const productStripeId =
          thisMonth > parseInt(siteSettings.freeSubscriptionAfterMonth)
            ? siteSettings.familyMembershipOnlyRegProduct
            : siteSettings.familyMembershipRegProduct;
        setProductDetails({
          regFee: siteSettings.familyMembershipRegFee,
          memFee: subscriptionFee,
          total:
            parseInt(siteSettings.familyMembershipRegFee) + subscriptionFee,
          productId: productStripeId,
        });
      } else {
        const subscriptionFee =
          thisMonth > parseInt(siteSettings.freeSubscriptionAfterMonth)
            ? 0
            : parseInt(siteSettings.singleMembershipRenewFee);
        const productStripeId =
          thisMonth > parseInt(siteSettings.freeSubscriptionAfterMonth)
            ? siteSettings.singleMembershipOnlyRegProduct
            : siteSettings.singleMembershipRegProduct;
        setProductDetails({
          regFee: siteSettings.singleMembershipRegFee,
          memFee: subscriptionFee,
          total:
            parseInt(siteSettings.singleMembershipRegFee) + subscriptionFee,
          productId: productStripeId,
        });
      }
    }
  };

  const redirectToCheckout = async (checkoutOptions) => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log(error);
  };

  const payNow = async () => {
    setLoading(true);
    const transaction = await fetch(
      `${api}/collections/save/membertransactions`,
      {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            userId: memId,
            transactionTitle: `${userDetails.membership} Registration + 1 Year Subscription `,
            transactionAmount: productDetails.total,
            year: new Date().getFullYear(),
            status: 'pending',
            striperef: productDetails.productId,
            qty: 1,
            membershipType: userDetails.membership,
            memberName: userDetails.fullName,
            memberEmail: userDetails.email,
            paymentType: 'registration',
          },
        }),
      }
    );
    const transactionSaved = await transaction.json();

    const item = {
      price: productDetails.productId,
      quantity: 1,
    };

    const checkoutOptions = {
      lineItems: [item],
      mode: 'payment',
      successUrl: `${window.location.origin}/reg-payment-success/${transactionSaved._id}/${memId}`,
      cancelUrl: `${window.location.origin}/payment-cancel/${transactionSaved._id}`,
      customerEmail: userDetails.email,
      clientReferenceId: `${memId}--${transactionSaved._id}`,
    };
    redirectToCheckout(checkoutOptions);
  };

  useEffect(() => {
    if (store.siteSettings) {
      getMember();
    }
  }, [store.siteSettings]);

  return (
    <div className='wrapper'>
      <img src={logo} width='200' />
      <br />
      {userDetails && (
        <>
          <h2>Pay Your Registration Fee</h2>
          {userDetails.paymentStatus ? (
            <>
              <p>You have already paid the registration fees.</p>
              <Link to='/home'>Go to Home</Link>
            </>
          ) : (
            <>
              <p>Confirm your details are correct and click PAY button.</p>
              <span>
                Name: {userDetails.fullName}
                <br />
                Post code: {userDetails.postcode}
              </span>
              <br />
              {productDetails.regFee && (
                <>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 320 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align='right'>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            Registration
                          </TableCell>
                          <TableCell align='right'>
                            £{productDetails.regFee}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            1 Year subscription
                          </TableCell>
                          <TableCell align='right'>
                            £{productDetails.memFee}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            <strong> Total</strong>
                          </TableCell>
                          <TableCell align='right'>
                            <strong>£{productDetails.total}</strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  <button className='btn' disabled={loading} onClick={payNow}>
                    <strong>
                      {loading ? 'Loading...' : `PAY  £${productDetails.total}`}
                    </strong>
                  </button>
                </>
              )}
            </>
          )}
        </>
      )}
      {!userDetails && (
        <>
          <h2>Account Not Found</h2>
          <Link to='/home'>Go to Home</Link>
        </>
      )}
    </div>
  );
};

export default RegPay;
