import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { api, apiToken } from '../../config';
import { ThemeProvider, createTheme } from '@mui/material';
import './table-style.css';
import { toast } from 'react-toastify';
import MemberPopup from './MemberPopup';
import { timeFormat } from '../../utility';
import { getAge } from '../../utility';
import { useLocation } from 'react-router-dom';

const MemberListTable = () => {
  const location = useLocation();
  const pagePath = location.pathname
  const defaultMaterialTheme = createTheme();
  const columns = [
    {
      title: 'Member ID',
      field: 'memberId',
      emptyValue: () => 'Not Generated',
    },
    { title: 'Name', field: 'fullName' },
    { title: 'Membership Type', field: 'membership' },
    {
      title: 'Status',
      field: 'status',
      lookup: { true: 'Active', false: 'Pending' },
      defaultSort: 'desc',
    },
    {
      title: 'Date',
      field: '_created',
      // render: (rowData) => timeFormat(rowData._created, false),
    },
    {
      title: 'Membership Fee',
      field: 'paymentStatus',
      lookup: { true: 'Paid', false: 'Not Paid' },
    },
    { title: 'Email', field: 'email' },
    { title: 'Mobile', field: 'mobile' },
    { title: 'Spouse Name', field: 'spouseName' },
    { title: 'Children Count', field: 'childCount', align: 'center' },
    { title: 'Children', field: 'children' },
    { title: 'Last subscription year', field: 'lastSubscriptionRenewedYear' },
    {
      title: 'Role',
      field: 'role',
      lookup: { member: 'Member', accountant: 'Admin' },
    },
  ];

  const tableOptions = {
    pageSizeOptions: [50, 100, 250, 500],
    pageSize: 15,
    exportButton: true,
    exportAllData: true,
    actionsColumnIndex: -1,
    rowStyle: (rowData) =>
      rowData.status === false
        ? { backgroundColor: 'rgb(255 215 215)' }
        : parseInt(rowData.lastSubscriptionRenewedYear) <
          new Date().getFullYear()
        ? { backgroundColor: 'rgb(255 235 188)' }
        : {},
  };
  const [members, setMembers] = useState(null);
  const [member, setMember] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getMemberList = async () => {
    const memListData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { delete: pagePath === '/deleted-members'? true: false },
      }),
    });

    const prepareChildList = (item) => {
      var childCount = parseInt(item.childCount);
      let result = '';
      if (childCount > 0) {
        for (var i = 1; i <= childCount; i++) {
          let childName = item[`childName${i}`];
          let childYear = item[`childYear${i}`];
          let childAge = getAge(childYear);
          result += `${childName} (${childAge}), `;
        }
      }
      return result;
    };

    const memData = await memListData.json();
    const memberListData = memData.entries.map((item) => ({
      ...item,
      _created: timeFormat(item._created, false),
      children: prepareChildList(item),
    }));
    setMembers(memberListData);
  };
  useEffect(() => {
    getMemberList();
  }, [pagePath]);

  const handleCopyPayLink = async (data) => {
    const payLink = `${window.location.origin}/payment-reg-fee/${data._id}`;
    navigator.clipboard.writeText(payLink);
    // send email
    const phpEmail = await fetch(
      `https://cockpit.mmaonline.org.uk/email/send-email.php`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            subject: 'MMA - Membership',
            recipient: data.email,
            body: `Hi, <br/><br/>
            Thank you for your application for the membership of Manchester Malayalee Association. I am pleased to inform you that the Trustees Board has approved your application at the recent meeting.<br/><br/>
            Please pay the one-off registration fees of £10 (£20 for family membership) and the first year's annual subscription fees of £10 (£20 for family) within 14 days of receiving this email. You can pay online by clicking the below link.<br/><br/>
            ${payLink}<br/><br/>
            Please note that non-payment of the membership and subscription fees within the 14 day period mentioned above will result in automatic cancellation of your application and you will be required to apply again. <br/><br/>
            Looking forward to seeing you soon at one of the MMA events. <br/><br/>
            Kind regards,<br/>
            <b>Secretary<br/>
            Manchester Malayalee Association</b><br/>
            Reg. Charity 1175205
            `,
          },
        }),
      }
    );
    const res = await phpEmail.json();
    if (res.status === 'success') {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const showDetails = (e, data) => {
    // setMember(data);
    // setShowModal(true);
    window
      .open(`${window.location.origin}/member-details/${data._id}`, '_blank')
      .focus();
  };

  const handleDeleteMember = async ({ _id, fullName }) => {
    let confirmDelete = window.confirm(`Are you sure to ${pagePath==='/deleted-members'? 'restore':'delete'} ${fullName}?`);
    if (confirmDelete) {
      await fetch(`${api}/collections/save/userlist`, {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            _id: _id,
            delete: pagePath==='/deleted-members'?false:true,
          },
        }),
      });
      const memList = [...members];
      const updatedList = memList.filter((item) => item._id != _id);
      setMembers(updatedList);
      toast.success(`${pagePath==='/deleted-members'? 'Restored':'Deleted'} ${fullName}`);
    }
  };

  const handlePrivilege = async (data) => {
    const { _id, role, fullName } = data;
    if (window.confirm(`Are you sure change privilege of ${fullName}?`)) {
      await fetch(`${api}/collections/save/userlist`, {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            _id: _id,
            role: role === 'accountant' ? 'member' : 'accountant',
          },
        }),
      });
      const memList = [...members];
      const updatedList = memList.map((item) =>
        item._id === _id
          ? {
              ...item,
              role: role === 'accountant' ? 'member' : 'accountant',
            }
          : item
      );
      setMembers(updatedList);
      toast.success(`${fullName}'s privilege modified.`);
    }
  };

  const tableActions = [
    {
      icon: () => <i class='fa fa-file-text-o' aria-hidden='true'></i>,
      tooltip: 'Show Details',
      onClick: showDetails,
    },
    (rowData) => ({
      icon: () => <i class='fa fa-envelope' aria-hidden='true'></i>,
      tooltip: 'Send Payment Link',
      onClick: async (e, data) => await handleCopyPayLink(data),
      disabled: rowData.paymentStatus,
    }),
    {
      icon: () => <i class={pagePath==='/deleted-members'?'fa fa-retweet':'fa fa-trash'} aria-hidden='true'></i>,
      tooltip: pagePath==='/deleted-members'?'Restore Member':'Delete Member',
      onClick: (e, data) => handleDeleteMember(data),
    },
    (rowData) => ({
      icon: () =>
        rowData.role === 'member' ? (
          <i class='fa fa-user' aria-hidden='true'></i>
        ) : (
          <i class='fa fa-user-secret' aria-hidden='true'></i>
        ),
      tooltip:
        rowData.role === 'member'
          ? 'Give admin privilege '
          : 'Revoke privilege',
      onClick: (e, data) => handlePrivilege(data),
      disabled: !rowData.status,
    }),
  ];

  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        {members && (
          <MaterialTable
            columns={columns}
            data={members}
            title='Members'
            options={tableOptions}
            actions={tableActions}
          />
        )}
      </ThemeProvider>
      {member && (
        <MemberPopup
          open={showModal}
          handleClose={() => setShowModal(false)}
          member={member}
        />
      )}
    </div>
  );
};

export default MemberListTable;
