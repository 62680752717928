import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [store, setStore] = useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    if (!store.auth) {
      const lclAuth = localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth"))
        : null;
      setStore((prev) => ({
        ...prev,
        auth: lclAuth,
      }));
      setIsAuthenticated(lclAuth ? lclAuth.status : false);
    } else {
      setIsAuthenticated(store.auth.status);
    }
  }, [store.auth]);

  const getAuth = () => {
    if (store.auth) {
    } else {
      setStore((prev) => ({
        ...prev,
        auth: localStorage.getItem("auth")
          ? JSON.parse(localStorage.getItem("auth"))
          : null,
      }));
    }
  };

  useEffect(() => {
    getAuth();
  }, []);
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <>
            {localStorage.setItem("redirectAfterLogin", restOfProps.path)}
            <Redirect to="/login" />
          </>
        )
      }
    />
  );
}

export default ProtectedRoute;
