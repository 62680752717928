import React, { useState, useContext, useEffect } from 'react';
import './ticket.css';
import { loadStripe } from '@stripe/stripe-js';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken } from '../../config';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};
const Ticket = ({
  subTitle,
  title,
  date,
  email,
  memberId,
  membershipType,
  qty,
  memberEmail,
  memberName,
  lastRenewed,
}) => {
  const [store, setStore] = useContext(AppContext);
  const [submitted, setSubmitted] = useState(false);
  const [productDetails, setProductDetails] = useState({
    regFee: null,
    memFee: null,
    total: null,
    productId: null,
  });

  const redirectToCheckout = async (checkoutOptions) => {
    console.log('redirectToCheckout');
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log(error);
  };

  const payNow = async () => {
    setSubmitted(true);
    const year = new Date().getFullYear();
    const transaction = await fetch(
      `${api}/collections/save/membertransactions`,
      {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            userId: memberId,
            transactionTitle: `${title} - ${subTitle} - ${year}`,
            transactionAmount: productDetails.amount * qty,
            year: year,
            status: 'pending',
            striperef: productDetails.productId,
            qty: qty,
            membershipType: membershipType,
            memberName: memberName,
            memberEmail: memberEmail,
            paymentType: 'renew',
          },
        }),
      }
    );

    const item = {
      price: productDetails.productId,
      quantity: qty,
    };

    const transactionSaved = await transaction.json();
    const checkoutOptions = {
      lineItems: [item],
      mode: 'payment',
      successUrl: `${window.location.origin}/payment-success/${transactionSaved._id}/${memberId}`,
      cancelUrl: `${window.location.origin}/payment-cancel/${transactionSaved._id}`,
      customerEmail: email,
      clientReferenceId: `${memberId}--${transactionSaved._id}`,
    };
    redirectToCheckout(checkoutOptions);
  };

  useEffect(() => {
    if (store.siteSettings) {
      const { siteSettings } = store;
      if (membershipType === 'Family') {
        setProductDetails({
          memFee: siteSettings.familyMembershipRenewFee,
          amount: parseInt(siteSettings.familyMembershipRenewFee),
          productId: siteSettings.familyMembershipRenewProduct,
        });
      } else {
        setProductDetails({
          memFee: siteSettings.singleMembershipRenewFee,
          amount: parseInt(siteSettings.singleMembershipRenewFee),
          productId: siteSettings.singleMembershipRenewProduct,
        });
      }
    }
  }, [store.siteSettings]);

  return (
    <div className='ticket-wrapper'>
      <div className='ticket-top'>
        <div className='text-part'>
          <span className='mini-text'>{subTitle}</span>
          <h2>{title}</h2>
          <span className='date-part'>{date}</span>
          <br />
          <button
            className='btn'
            onClick={payNow}
            disabled={submitted || lastRenewed == new Date().getFullYear()}
          >
            {submitted
              ? `Loading...`
              : lastRenewed == new Date().getFullYear()
              ? `You have already paid!`
              : `PAY NOW £${productDetails.amount * qty}`}
          </button>
        </div>
        <div className='image-part'></div>
      </div>
      <div className='ticket-bottom'>
        <span>SUBSCRIPTION FEE</span>
        <span>£{productDetails.amount}</span>
      </div>
    </div>
  );
};

export default Ticket;
