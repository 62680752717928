import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Redirect } from 'react-router-dom';
import AdminMenu from './AdminMenu';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { api, apiToken } from '../../config';

import './style.css';
import RenewedList from './RenewedList';
import NonRenewedList from './NonRenewedList';

const RenewList = (props) => {
  const [store, setStore] = useContext(AppContext);
  const [renewedMembers, setRenewedMembers] = useState(null);
  const [members, setMembers] = useState(null);
  const [paidMembersIds, setPaidMembersIds] = useState(null);
  const { auth } = store;
  const initialState = {
    name: '',
    year: new Date().getFullYear(),
  };
  const [value, setValue] = useState(initialState);
  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const getRenewedList = async () => {
    const memListData = await fetch(
      `${api}/collections/get/membertransactions`,
      {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          sort: { transactionTitle: -1 },
          filter: { year: value.year, status: 'success', paymentType: 'renew' },
        }),
      }
    );
    const members = await memListData.json();
    setRenewedMembers(members.entries);
    const paidMemIds = members.entries.map((item) => item.userId);
    setPaidMembersIds(paidMemIds);
  };

  const getMemberList = async (id) => {
    const memListData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { status: true, delete: false },
      }),
    });
    const members = await memListData.json();
    setMembers(members.entries);
  };
  useEffect(() => {
    getRenewedList();
    getMemberList();
  }, [value.year]);

  return (
    <div className='tp-case-details-area section-padding'>
      <div className='container-fluid'>
        <div className='row'>
          {auth && (
            <div className='col col-12'>
              {auth.role === 'accountant' ? (
                <div className='tp-blog-sidebar'>
                  <AdminMenu />
                  <br />
                  <br />
                  <RenewedList search={value} members={renewedMembers} />
                  <br />
                  <br />
                  {members && paidMembersIds && (
                    <NonRenewedList
                      members={members}
                      paidMembers={paidMembersIds}
                    />
                  )}
                </div>
              ) : (
                <Redirect to='/mem-account' />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenewList;
