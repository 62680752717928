import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import Admin from '../../components/Admin';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const MemAccount = () => {
  return (
    <Fragment>
      <Navbar />
      <Admin />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default MemAccount;
