export const timeFormat = (unix_timestamp, showTime = true) => {
  var date = new Date(unix_timestamp * 1000);
  var year = date.getFullYear();
  var month = date.getMonth();
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = '0' + date.getMinutes();
  var seconds = '0' + date.getSeconds();

  var formattedTime = `${day}-${month + 1}-${year}`;
  formattedTime += showTime ? ` ${hours} : ${minutes.substr(-2)}` : '';
  return formattedTime;
};

export const makeId = (length) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getAge = (childYear) => {
  var today = new Date();
  var birthDate = new Date(`${childYear}-01-01`);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const prepareDataForPeople = (entries) => {
  const mainKeys = [
    { name: 'adultName1', statusKey: 'adult1Status', type: 'Adult' },
    { name: 'adultName2', statusKey: 'adult2Status', type: 'Adult' },
    { name: 'parentName1', statusKey: 'parent1Status', type: 'Parent' },
    { name: 'parentName2', statusKey: 'parent2Status', type: 'Parent' },
    { name: 'parentName3', statusKey: 'parent3Status', type: 'Parent' },
    { name: 'parentName4', statusKey: 'parent4Status', type: 'Parent' },
    {
      name: 'above13Child1',
      statusKey: 'above13Child1Status',
      type: 'Above 13',
    },
    {
      name: 'above13Child2',
      statusKey: 'above13Child2Status',
      type: 'Above 13',
    },
    {
      name: 'above13Child3',
      statusKey: 'above13Child3Status',
      type: 'Above 13',
    },
    {
      name: 'above13Child4',
      statusKey: 'above13Child4Status',
      type: 'Above 13',
    },
    {
      name: 'above13Child5',
      statusKey: 'above13Child5Status',
      type: 'Above 13',
    },
    { name: 'above5Child1', statusKey: 'above5Child1Status', type: 'Above 5' },
    { name: 'above5Child2', statusKey: 'above5Child2Status', type: 'Above 5' },
    { name: 'above5Child3', statusKey: 'above5Child3Status', type: 'Above 5' },
    { name: 'above5Child4', statusKey: 'above5Child4Status', type: 'Above 5' },
    { name: 'above5Child5', statusKey: 'above5Child5Status', type: 'Above 5' },
    { name: 'below5Child1', statusKey: 'below5Child1Status', type: 'Below 5' },
    { name: 'below5Child2', statusKey: 'below5Child2Status', type: 'Below 5' },
    { name: 'below5Child3', statusKey: 'below5Child3Status', type: 'Below 5' },
  ];
  const peopleArray = [];
  for (let prop of mainKeys) {
    const name = prop['name'];
    const status = prop['statusKey'];
    const type = prop['type'];
    if (entries[name])
      peopleArray.push({
        name: entries[name],
        status: entries[status],
        key: name,
        statusKey: status,
        type: type,
        selection: false,
      });
  }
  return peopleArray;
};
