import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import "./members.scss";
import { timeFormat } from "../../utility";

const WomensDayTicketWrapper = ({ search, tickets }) => {
  const defaultMaterialTheme = createTheme();
  const { event, year } = search;
  const [ticketListData, setTicketListData] = useState(null);

  const columns = [
    { title: "Date", field: "_created" },
    { title: "Email", field: "email" },
    { title: "Name", field: "name" },
    { title: "Amount", field: "price" },
    { title: "Children", field: "numberOfChildren" },
  ];

  const tableOptions = {
    pageSizeOptions: [15, 50, 100, 250, 500],
    pageSize: 15,
    exportButton: true,
    exportAllData: true,
    actionsColumnIndex: -1,
    rowStyle: (rowData) =>
      rowData.verificationStatus === true ? { backgroundColor: "#f1f1f1" } : {},
  };

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ year:", year);
    console.log(Object.keys(tickets));

    if (tickets.entries) {
      const formattedTickets = tickets.entries.map((item) => ({
        ...item,
        _created: timeFormat(item._created, false),
      }));
      setTicketListData(formattedTickets);
    } else {
      setTicketListData(null);
    }
  }, [tickets]);
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        {ticketListData && (
          <MaterialTable
            columns={columns}
            data={ticketListData}
            title="Tickets"
            options={tableOptions}
          />
        )}
      </ThemeProvider>
    </div>
  );
};

export default WomensDayTicketWrapper;
