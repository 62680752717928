import React, { useContext } from 'react';
import './style.css';
import { AppContext } from '../../contexts/AppContext';

const CtaSection = (props) => {
  const [state, setState] = useContext(AppContext);
  return (
    <div className='cta-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-12 col-12'>
            <div className='cta-left'>
              <h2>Contact Us </h2>
            </div>
          </div>
          <div className='col-lg-7 offset-lg-1 col-md-12 col-12'>
            <div className='cta-wrap'>
              <div className='cta-call'>
                <span>Call Us!</span>

                {state.siteSettings && (
                  <a href={`tel: ${state.siteSettings.PrimaryContactNumber}`}>
                    <h3>{state.siteSettings.PrimaryContactNumber}</h3>
                  </a>
                )}
              </div>
              <div className='cta-call'>
                <span>E-mail Us!</span>
                <h3>
                  {state.siteSettings && (
                    <a
                      href={`mailto: ${state.siteSettings.PrimaryContactEmail}`}
                    >
                      <h3>{state.siteSettings.PrimaryContactEmail}</h3>
                    </a>
                  )}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
