import React from 'react';

import TeamSection from '../team';
import './style.css';

import vimg from '../../images/volunteer.jpg';

const Volunteer = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className='volunteer-page'>
      <TeamSection addclass='v2' />
    </div>
  );
};

export default Volunteer;
