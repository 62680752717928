import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import EventSingle from '../../components/EventSingle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const Blog = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={'Latest News'} pagesub={'Blog'} />
      <EventSingle />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default Blog;
