import React from 'react';
import './cookieConcent.css';

const CookieConcent = ({ onAccept }) => {
  return (
    <div className='cookieConcentWrapper'>
      <img src='/cookies.png'></img>
      <h3>We use cookies</h3>
      <p>
        This website uses cookies to ensure you get the best experience on our
        website.
      </p>
      <button onClick={onAccept}>Accept</button>
    </div>
  );
};

export default CookieConcent;
