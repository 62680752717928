import React, { useState, useContext, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { AppContext } from "../../contexts/AppContext";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { api, apiToken } from "../../config";
import { useHistory } from "react-router-dom";

import "./book-ticket.scss";
import { getAge } from "../../utility";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};

const BookTicketForm = () => {
  const history = useHistory();
  const thisYear = new Date().getFullYear();
  const [store, setStore] = useContext(AppContext);
  const { auth, siteSettings } = store;
  const [product, setProduct] = useState({
    price: "",
    qty: 1,
    stripeId: "",
  });
  const [christmasProduct, setChristmasProduct] = useState([]);
  const initialState = {
    numberOfAdults: "1",
    adultName1: "",
    adultName2: "",
    numberOfParents: "0",
    parentName1: "",
    parentName2: "",
    parentName3: "",
    parentName4: "",
    numberOfChildrenAbove13: "0",
    above13Child1: "",
    above13Child2: "",
    above13Child3: "",
    above13Child4: "",
    above13Child5: "",
    numberOfChildrenAbove5: "0",
    above5Child1: "",
    above5Child2: "",
    above5Child3: "",
    above5Child4: "",
    above5Child5: "",
    numberOfChildrenBelow5: "0",
    below5Child1: "",
    below5Child2: "",
    below5Child3: "",
    email: "",
    membership: "",
    title: "",
    year: thisYear,
  };
  const [value, setValue] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const payNow = async (ticketId) => {
    const transaction = await fetch(
      `${api}/collections/save/membertransactions`,
      {
        headers: {
          "Cockpit-Token": apiToken,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          data: {
            userId: auth._id,
            transactionTitle: `${siteSettings.UpComingEventTicketTitle} - ${thisYear} `,
            // transactionAmount: product.price,
            transactionAmount:
              product.price * product.qty <
              parseInt(siteSettings.maxTicketPrice)
                ? product.price
                : siteSettings.maxTicketPrice,
            year: new Date().getFullYear(),
            status: "pending",
            // striperef: product.stripeId,
            striperef:
              product.price * product.qty <
              parseInt(siteSettings.maxTicketPrice)
                ? product.stripeId
                : siteSettings.UpComingEventFamilyTicketStripeId,
            // qty: product.qty,
            qty:
              product.price * product.qty <
              parseInt(siteSettings.maxTicketPrice)
                ? product.qty
                : 1,
            membershipType: auth.membership,
            memberName: auth.fullName,
            memberEmail: auth.email,
            paymentType: "ticket",
          },
        }),
      }
    );
    const transactionSaved = await transaction.json();

    let items = [];
    if (siteSettings && siteSettings.ticketType == "Christmas") {
      const itemsToSend = christmasProduct.filter((item) => item.qty > 0);
      items = itemsToSend.map((item) => ({
        price: item.stripeId,
        quantity: item.qty,
      }));
    } else {
      items = [
        {
          // price: product.stripeId,
          // quantity: product.qty,
          price:
            product.price * product.qty < parseInt(siteSettings.maxTicketPrice)
              ? product.stripeId
              : siteSettings.UpComingEventFamilyTicketStripeId,
          quantity:
            product.price * product.qty < parseInt(siteSettings.maxTicketPrice)
              ? product.qty
              : 1,
        },
      ];
    }
    console.log(items, christmasProduct);
    const checkoutOptions = {
      lineItems: items,
      mode: "payment",
      successUrl: `${window.location.origin}/ticket-payment-success/${ticketId}/${transactionSaved._id}/${auth._id}`,
      cancelUrl: `${window.location.origin}/payment-cancel/${transactionSaved._id}`,
      customerEmail: auth.email,
      clientReferenceId: `${auth._id}--${transactionSaved._id}`,
    };
    redirectToCheckout(checkoutOptions);
  };

  const bookTicket = async () => {
    const dataToSend = { ...value };
    const numOfAdults = parseInt(dataToSend.numberOfAdults);
    const numberOfParents = parseInt(dataToSend.numberOfParents);
    const numberOfChildrenAbove13 = parseInt(
      dataToSend.numberOfChildrenAbove13
    );
    const numberOfChildrenAbove5 = parseInt(dataToSend.numberOfChildrenAbove5);
    const numberOfChildrenBelow5 = parseInt(dataToSend.numberOfChildrenBelow5);
    if (numOfAdults < 2) {
      for (let i = numOfAdults; i <= 2; i++) {
        dataToSend[`adultName${i + 1}`] = "";
      }
    }
    if (numberOfParents < 2) {
      for (let i = numberOfParents; i <= 2; i++) {
        dataToSend[`parentName${i + 1}`] = "";
      }
    }
    if (numberOfChildrenAbove13 < 5) {
      for (let i = numberOfChildrenAbove13; i <= 5; i++) {
        dataToSend[`above13Child${i + 1}`] = "";
      }
    }
    if (numberOfChildrenAbove5 < 5) {
      for (let i = numberOfChildrenAbove5; i <= 5; i++) {
        dataToSend[`above5Child${i + 1}`] = "";
      }
    }
    if (numberOfChildrenBelow5 < 3) {
      for (let i = numberOfChildrenBelow5; i <= 3; i++) {
        dataToSend[`below5Child${i + 1}`] = "";
      }
    }
    setLoading(true);

    const booking = await fetch(`${api}/collections/save/tickets`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        data: {
          ...dataToSend,
          userId: auth._id,
          // ticketPrice: product.price * product.qty,
          ticketPrice:
            product.price * product.qty < parseInt(siteSettings.maxTicketPrice)
              ? product.price * product.qty
              : siteSettings.maxTicketPrice,
        },
      }),
    });
    const bookingSaved = await booking.json();
    if (parseInt(product.price) > 0) {
      payNow(bookingSaved._id);
    } else {
      history.push(`/ticket-success/${bookingSaved._id}/${auth._id}`);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      bookTicket();
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };

  useEffect(() => {
    if (store.auth && store.siteSettings) {
      getMember(auth._id);

      setValue((prev) => ({
        ...prev,
        email: auth.email,
        adultName1: auth.fullName,
        membership: auth.membership,
        title: `${siteSettings.UpComingEventTicketTitle} - ${thisYear}`,
      }));

      if (store.siteSettings.ticketType != "Christmas") {
        // setProduct((prev) => ({
        //   ...prev,
        //   price:
        //     auth.membership === 'Family'
        //       ? store.siteSettings.UpComingEventFamilyTicketPrice
        //       : store.siteSettings.UpComingEventSingleTicketPrice,
        //   stripeId:
        //     auth.membership === 'Family'
        //       ? store.siteSettings.UpComingEventFamilyTicketStripeId
        //       : store.siteSettings.UpComingEventSingleTicketStripeId,
        // }));
        // New Individual ticket logic
        setProduct((prev) => ({
          ...prev,
          price: store.siteSettings.UpComingEventSingleTicketPrice,
          stripeId: store.siteSettings.UpComingEventSingleTicketStripeId,
        }));
      } else {
        setProduct((prev) => ({
          ...prev,
          price: store.siteSettings.christmasAdultsTicketPrice,
          stripeId: store.siteSettings.christmasAdultsTicketStipeId,
        }));
      }

      const today = new Date();
      var endDate = new Date(siteSettings.UpComingEventTicketLastBookingDate);
      setExpired(today > endDate);
    }
  }, [store.auth, store.siteSettings]);

  const getMember = async (id) => {
    setLoading(true);
    const memListData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { _id: id, delete: false },
      }),
    });
    const members = await memListData.json();
    setLoading(false);
    if (members.entries.length > 0) {
      const me = members.entries[0];
      // find kids age
      let numberOfChildrenAbove13 = 0;
      let numberOfChildrenAbove5 = 0;
      let numberOfChildrenBelow5 = 0;
      let above13Child = { 1: "", 2: "", 3: "", 4: "", 5: "" };
      let above5Child = { 1: "", 2: "", 3: "", 4: "", 5: "" };
      let below5Child = { 1: "", 2: "", 3: "" };
      for (var i = 1; i <= 5; i++) {
        let childAge = getAge(me["childYear" + i]);
        let childName = me["childName" + i];
        if (!isNaN(childAge) && childName !== null) {
          if (childAge > 13) {
            numberOfChildrenAbove13++;
            above13Child[numberOfChildrenAbove13] = childName;
          } else if (childAge > 5) {
            numberOfChildrenAbove5++;
            above5Child[numberOfChildrenAbove5] = childName;
          } else if (childAge <= 5) {
            numberOfChildrenBelow5++;
            below5Child[numberOfChildrenBelow5] = childName;
          }
        }
      }
      const valuesToUpdate = {
        numberOfAdults: me.membership === "Family" ? "2" : "1",
        adultName2: me.membership === "Family" ? me.spouseName : "",
        numberOfChildrenAbove13,
        numberOfChildrenAbove5,
        numberOfChildrenBelow5,
        above13Child1: above13Child[1],
        above13Child2: above13Child[2],
        above13Child3: above13Child[3],
        above5Child1: above5Child[1],
        above5Child2: above5Child[2],
        above5Child3: above5Child[3],
        below5Child1: below5Child[1],
        below5Child2: below5Child[2],
        below5Child3: below5Child[3],
      };
      setValue((prev) => ({
        ...prev,
        ...valuesToUpdate,
      }));
    } else {
      window.location.href = `${window.location.origin}/logout`;
    }
  };

  const redirectToCheckout = async (checkoutOptions) => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log(error);
  };

  useEffect(() => {
    if (store.siteSettings && store.siteSettings.ticketType == "Individual") {
      let count = 1;
      switch (store.siteSettings.ticketPattern) {
        case "A":
          count = parseInt(value.numberOfAdults);
          break;
        case "A+P":
          count =
            parseInt(value.numberOfAdults) + parseInt(value.numberOfParents);
          break;
        case "A+13":
          count =
            parseInt(value.numberOfAdults) +
            parseInt(value.numberOfChildrenAbove13);
          break;
        case "A+13+P":
          count =
            parseInt(value.numberOfAdults) +
            parseInt(value.numberOfChildrenAbove13) +
            parseInt(value.numberOfParents);
          break;
        case "A+13+5":
          count =
            parseInt(value.numberOfAdults) +
            parseInt(value.numberOfChildrenAbove13) +
            parseInt(value.numberOfChildrenAbove5);
          break;
        case "A+13+5+P":
          count =
            parseInt(value.numberOfAdults) +
            parseInt(value.numberOfChildrenAbove13) +
            parseInt(value.numberOfChildrenAbove5) +
            parseInt(value.numberOfParents);
          break;
        case "A+13+5-5":
          count =
            parseInt(value.numberOfAdults) +
            parseInt(value.numberOfChildrenAbove13) +
            parseInt(value.numberOfChildrenAbove5) +
            parseInt(value.numberOfChildrenBelow5);
          break;
        case "A+13+5-5+P":
          count =
            parseInt(value.numberOfAdults) +
            parseInt(value.numberOfChildrenAbove13) +
            parseInt(value.numberOfChildrenAbove5) +
            parseInt(value.numberOfChildrenBelow5) +
            parseInt(value.numberOfParents);
          break;
      }
      setProduct((prev) => ({
        ...prev,
        qty: count,
      }));
    } else if (
      store.siteSettings &&
      store.siteSettings.ticketType == "Christmas"
    ) {
      let adultsCount =
        parseInt(value.numberOfAdults) + parseInt(value.numberOfParents);
      // let kidsCount =
      //   parseInt(value.numberOfChildrenAbove13) +
      //   parseInt(value.numberOfChildrenAbove5);
      // let finalKidsCount = kidsCount > 1 ? 1 : kidsCount;

      setChristmasProduct([
        {
          price: siteSettings.christmasAdultsTicketPrice,
          qty: adultsCount,
          stripeId: siteSettings.christmasAdultsTicketStipeId,
        },
        {
          price: siteSettings.christmasKids13TicketPrice,
          qty: parseInt(value.numberOfChildrenAbove13),
          stripeId: siteSettings.christmasKids13TicketStipeId,
        },
        {
          price: siteSettings.christmasKidsTicketPrice,
          qty: parseInt(value.numberOfChildrenAbove5),
          stripeId: siteSettings.christmasKidsTicketStipeId,
        },
      ]);
      setProduct((prev) => ({
        ...prev,
        price:
          siteSettings.christmasKidsTicketPrice *
            parseInt(value.numberOfChildrenAbove5) +
          siteSettings.christmasKids13TicketPrice *
            parseInt(value.numberOfChildrenAbove13) +
          siteSettings.christmasAdultsTicketPrice * adultsCount,
        qty: 1,
      }));
    } else {
      setProduct((prev) => ({
        ...prev,
        qty: 1,
      }));
    }
  }, [value]);

  return (
    <Grid className="bookTicketForm">
      <h2>
        {siteSettings && siteSettings.UpComingEventTicketTitle} {thisYear}
      </h2>
      <h4>Read carefully & Book your ticket</h4>
      <br />
      {auth && (
        <form onSubmit={submitForm}>
          <Grid className="loginForm signupForm">
            {auth && auth.membership === "Family" && (
              <h4 style={{ color: "#aa0000" }}>You and your spouse</h4>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="title-label">Number of adults</InputLabel>
                  <Select
                    className="inputOutline"
                    id="numberOfAdults"
                    labelId="numberOfAdults-label"
                    name="numberOfAdults"
                    value={value.numberOfAdults}
                    label="Number of adults"
                    placeholder="Number of adults"
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                    disabled={auth.membership === "Single"}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                  </Select>
                  {validator.message(
                    "numberOfAdults",
                    value.numberOfAdults,
                    "required"
                  )}
                </FormControl>
              </Grid>
              {Array.apply(null, { length: value.numberOfAdults }).map(
                (e, i) => (
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          className="inputOutline"
                          fullWidth
                          placeholder={`Name ${i + 1}`}
                          value={value[`adultName${i + 1}`]}
                          variant="outlined"
                          name={`adultName${i + 1}`}
                          label={`Name ${i + 1}`}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        />
                        {validator.message(
                          `adultName${i + 1}`,
                          value[`adultName${i + 1}`],
                          "required"
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
            {auth && auth.membership === "Family" && (
              <>
                <Grid container spacing={3} style={{ marginTop: "30px" }}>
                  <Grid item xs={12} md={12}>
                    <h4 style={{ color: "#aa0000" }}>Children (above 13)</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="title-label">
                        Children (above 13)
                      </InputLabel>
                      <Select
                        className="inputOutline"
                        id="numberOfChildrenAbove13"
                        labelId="numberOfChildrenAbove13-label"
                        name="numberOfChildrenAbove13"
                        value={value.numberOfChildrenAbove13}
                        label="Number of children"
                        placeholder="Number of children"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {Array.apply(null, {
                    length: value.numberOfChildrenAbove13,
                  }).map((e, i) => (
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            className="inputOutline"
                            fullWidth
                            placeholder={`Name ${i + 1}`}
                            value={value[`above13Child${i + 1}`]}
                            variant="outlined"
                            name={`above13Child${i + 1}`}
                            label={`Child Name ${i + 1}`}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                          />
                          {validator.message(
                            `above13Child${i + 1}`,
                            value[`above13Child${i + 1}`],
                            "required"
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid container spacing={3} style={{ marginTop: "30px" }}>
                  <Grid item xs={12} md={12}>
                    <h4 style={{ color: "#aa0000" }}>Children (above 5)</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="title-label">
                        Children (above 5)
                      </InputLabel>
                      <Select
                        className="inputOutline"
                        id="numberOfChildrenAbove5"
                        labelId="numberOfChildrenAbove5-label"
                        name="numberOfChildrenAbove5"
                        value={value.numberOfChildrenAbove5}
                        label="Number of kids"
                        placeholder="Number of kids"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {Array.apply(null, {
                    length: value.numberOfChildrenAbove5,
                  }).map((e, i) => (
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            className="inputOutline"
                            fullWidth
                            placeholder={`Name ${i + 1}`}
                            value={value[`above5Child${i + 1}`]}
                            variant="outlined"
                            name={`above5Child${i + 1}`}
                            label={`Child Name ${i + 1}`}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                          />
                          {validator.message(
                            `above5Child${i + 1}`,
                            value[`above5Child${i + 1}`],
                            "required"
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid container spacing={3} style={{ marginTop: "30px" }}>
                  <Grid item xs={12} md={12}>
                    <h4 style={{ color: "#aa0000" }}>Children (below 5)</h4>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="title-label">
                        Children (below 5)
                      </InputLabel>
                      <Select
                        className="inputOutline"
                        id="numberOfChildrenBelow5"
                        labelId="numberOfChildrenBelow5-label"
                        name="numberOfChildrenBelow5"
                        value={value.numberOfChildrenBelow5}
                        label="Number of kids"
                        placeholder="Number of kids"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {Array.apply(null, {
                    length: value.numberOfChildrenBelow5,
                  }).map((e, i) => (
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            className="inputOutline"
                            fullWidth
                            placeholder={`Name ${i + 1}`}
                            value={value[`below5Child${i + 1}`]}
                            variant="outlined"
                            name={`below5Child${i + 1}`}
                            label={`Kid Name ${i + 1}`}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                          />
                          {validator.message(
                            `below5Child${i + 1}`,
                            value[`below5Child${i + 1}`],
                            "required"
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={3} style={{ marginTop: "30px" }}>
                  <Grid item xs={12} md={12}>
                    <h4 style={{ color: "#aa0000" }}>For your parents</h4>
                    <p style={{ color: "#000", margin: 0 }}>
                      If they are here on a visiting visa
                    </p>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="title-label">
                        Number of parents
                      </InputLabel>
                      <Select
                        className="inputOutline"
                        id="numberOfParents"
                        labelId="numberOfParents-label"
                        name="numberOfParents"
                        value={value.numberOfParents}
                        label="Number of parents"
                        placeholder="Number of parents"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                      </Select>
                      {validator.message(
                        "numberOfParents",
                        value.numberOfParents,
                        "required"
                      )}
                    </FormControl>
                  </Grid>
                  {Array.apply(null, { length: value.numberOfParents }).map(
                    (e, i) => (
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              className="inputOutline"
                              fullWidth
                              placeholder={`Name ${i + 1}`}
                              value={value[`parentName${i + 1}`]}
                              variant="outlined"
                              name={`parentName${i + 1}`}
                              label={`Name ${i + 1}`}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onBlur={(e) => changeHandler(e)}
                              onChange={(e) => changeHandler(e)}
                            />
                            {validator.message(
                              `parentName${i + 1}`,
                              value[`parentName${i + 1}`],
                              "required"
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              </>
            )}
            <br />
            <Grid item xs={12}>
              <Grid className="formFooter">
                <p style={{ color: "red" }}>
                  Guests (who are in manchester for more than 6 months) are not
                  allowed in this ticket. <br />
                  If you wish to bring guests, please contact MMA (
                  {siteSettings && siteSettings.PrimaryContactNumber})
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid className="formFooter">
                {siteSettings && (
                  <Button
                    fullWidth
                    className="cBtn cBtnLarge cBtnTheme"
                    type="submit"
                    disabled={loading || expired}
                  >
                    {console.log(
                      "max ticket",
                      siteSettings.maxTicketPrice,
                      product.price * product.qty
                    )}
                    {expired
                      ? "Booking Not Available"
                      : loading
                      ? "Please wait..."
                      : `Book Now £${
                          product.price * product.qty <
                          parseInt(siteSettings.maxTicketPrice)
                            ? product.price * product.qty
                            : siteSettings.maxTicketPrice
                        }`}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Grid>
  );
};

export default BookTicketForm;
