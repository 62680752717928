import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import Scanner from './Scanner';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const QrScanner = () => {
  return (
    <Fragment>
      {/* <Navbar /> */}
      <Scanner />
      {/* <Footer /> */}
      {/* <Scrollbar /> */}
    </Fragment>
  );
};
export default QrScanner;
