import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import { api, apiToken } from '../../config';

import './style.scss';
import { makeId } from '../../utility';

const ForgotPassword = (props) => {
  const [value, setValue] = useState({
    email: '',
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: 'errorMessage',
    })
  );

  const requestResetPassword = async () => {
    const uData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: {
          email: value.email,
          delete: false,
        },
        fields: {
          email: 1,
          _id: 1,
        },
      }),
    });
    const user = await uData.json();
    if (user.entries.length > 0) {
      const userDetails = user.entries[0];
      // user exists , send mail
      const randomKey = makeId(10);
      const payLink = `${window.location.origin}/reset-account-password/${userDetails._id}/${randomKey}`;
      const phpEmail = await fetch(
        `https://cockpit.mmaonline.org.uk/email/send-email.php`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'post',
          body: JSON.stringify({
            data: {
              subject: 'MMA - Reset Password',
              recipient: userDetails.email,
              body: `We have received a password reset request for your MMA account. <br/>
              Please click following link to reset your password. <br/>
              ${payLink}<br/><br/>
              If you did not ask to change your password, then you can ignore this mail.<br/><br/>
              Kind regards,<br/>
              <b>Secretary<br/>
              Manchester Malayalee Association</b>
              `,
            },
          }),
        }
      );
      const res = await phpEmail.json();

      //   update random key
      const data = {
        _id: userDetails._id,
        resetKey: randomKey,
      };
      const updateUData = await fetch(`${api}/collections/save/userlist`, {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data,
        }),
      });

      setValue({
        email: '',
      });
      validator.hideMessages();
      toast.success('Please check your email!');
      props.history.push('/login');
    } else {
      toast.error('Email not found!');
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      await requestResetPassword();
    } else {
      validator.showMessages();
      toast.error('Empty field is not allowed!');
    }
  };
  return (
    <Grid className='loginWrapper'>
      <Grid className='loginForm'>
        <h2>Forgot Password</h2>
        <p>Reset your account password</p>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className='inputOutline'
                fullWidth
                placeholder='E-mail'
                value={value.email}
                variant='outlined'
                name='email'
                label='E-mail'
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message('email', value.email, 'required|email')}
            </Grid>
            <Grid item xs={12}>
              <Grid className='formFooter'>
                <Button
                  fullWidth
                  className='cBtn cBtnLarge cBtnTheme'
                  type='submit'
                >
                  Resend Password
                </Button>
              </Grid>
              <p className='noteHelp'>
                Already have an account?{' '}
                <Link to='/login'>Return to Sign In</Link>
              </p>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default withRouter(ForgotPassword);
