import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable, { MTableBody } from 'material-table';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';

import './members.scss';

const RenewedList = ({ members }) => {
  const defaultMaterialTheme = createTheme();

  const tableOptions = {
    pageSizeOptions: [15, 50, 100, 250, 500],
    pageSize: 15,
    exportButton: true,
    exportAllData: true,
    actionsColumnIndex: -1,
  };

  const columns = [
    { title: 'Name', field: 'memberName' },
    { title: 'Membership Type', field: 'membershipType' },
    { title: 'Amount', field: 'transactionAmount' },
    {
      title: 'Date',
      field: '_created',
      render: (rowData) =>
        new Date(parseInt(`${rowData._created}000`)).toLocaleDateString(
          'en-GB'
        ),
    },
    // {
    //   title: 'Stripe Fee',
    //   field: 'transactionAmount',
    //   render: (rowData) =>
    //     (parseFloat(rowData.transactionAmount) * 1.2) / 100 + 0.2,
    // },
    // {
    //   title: 'Payout',
    //   field: 'transactionAmount',
    //   render: (rowData) =>
    //     parseFloat(rowData.transactionAmount) -
    //     ((parseFloat(rowData.transactionAmount) * 1.2) / 100 + 0.2),
    // },
    { title: 'Email', field: 'memberEmail' },
    { title: 'Stripe Product', field: 'striperef' },
    { title: 'Description', field: 'transactionTitle' },
    { title: 'Year', field: 'year' },
  ];

  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        {members && (
          <MaterialTable
            columns={columns}
            data={members}
            title={`Subscription Renewed (${members.length})`}
            options={tableOptions}
            components={{
              Body: (props) => {
                let totalObj = {
                  actualSum: 0,
                  feeSum: 0,
                  payOut: 0,
                };
                props.renderData.forEach((rowData) => {
                  totalObj.actualSum += parseFloat(rowData.transactionAmount);
                  // totalObj.feeSum +=
                  //   parseFloat(rowData.transactionAmount) * 0.012 + 0.2;
                  // totalObj.payOut +=
                  //   parseFloat(rowData.transactionAmount) -
                  //   (parseFloat(rowData.transactionAmount) * 0.012 + 0.2);
                });
                return (
                  <>
                    <MTableBody {...props} />
                    <TableFooter>
                      <TableRow className='total-foot'>
                        <TableCell colSpan={1} />
                        <TableCell colSpan={1}>Total:</TableCell>
                        <TableCell colSpan={1}>£{totalObj.actualSum}</TableCell>
                        {/* <TableCell colSpan={1}>£{totalObj.feeSum}</TableCell>
                        <TableCell colSpan={1}>£{totalObj.payOut}</TableCell> */}
                        <TableCell colSpan={5} />
                      </TableRow>
                    </TableFooter>
                  </>
                );
              },
            }}
          />
        )}
      </ThemeProvider>
    </div>
  );
};

export default RenewedList;
