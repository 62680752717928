import React from "react";
import { Link } from "react-router-dom";

const AdminMenu = () => {
  return (
    <span>
      <Link to={"/accounts"} className="iconButton">
        <i className="fa fa-users" aria-hidden="true"></i> <span>Members</span>
      </Link>{" "}
      <Link to={"/deleted-members"} className="iconButton">
        <i className="fa fa-users" aria-hidden="true"></i>{" "}
        <span>Deleted Members</span>
      </Link>{" "}
      <Link to={"/mem-renewals"} className="iconButton">
        <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
        <span>Subscriptions</span>
      </Link>{" "}
      <Link to={"/mem-events-pay"} className="iconButton">
        <i className="fa fa-ticket" aria-hidden="true"></i>{" "}
        <span>Event Bookings</span>
      </Link>{" "}
      <Link to={"/scan-qr-code"} className="iconButton">
        <i className="fa fa-qrcode" aria-hidden="true"></i>{" "}
        <span>Scan Ticket</span>
      </Link>
      <Link to={"/manage-womensday"} className="iconButton">
        <i className="fa fa-ticket" aria-hidden="true"></i>{" "}
        <span>Womens Day</span>
      </Link>{" "}
    </span>
  );
};

export default AdminMenu;
