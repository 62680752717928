import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Redirect } from 'react-router-dom';

import MemberListTable from '../Tables/MemberListTable';

import './style.css';
import AdminMenu from './AdminMenu';

const Admin = (props) => {
  const [store, setStore] = useContext(AppContext);
  const { auth } = store;
  const initialState = {
    name: '',
    status: '',
  };
  const [value, setValue] = useState(initialState);
  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  return (
    <div className='tp-case-details-area section-padding'>
      <div className='container-fluid'>
        <div className='row'>
          {auth && (
            <div className='col col-12'>
              {auth.role === 'accountant' ? (
                <div className='tp-blog-sidebar'>
                  <AdminMenu />
                  <br />
                  <br />
                  <MemberListTable />
                </div>
              ) : (
                <Redirect to='/mem-account' />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Admin;
