import React, { useContext } from "react";
import ContactForm from "../ContactFrom";
import "./style.css";
import { AppContext } from "../../contexts/AppContext";

const Contactpage = () => {
  const [state, setState] = useContext(AppContext);

  return (
    <section className="contact-pg-contact-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-6 col-12">
            <div className="section-title-s3 section-title-s5">
              <h2>Our Contacts</h2>
            </div>
            <div className="contact-details">
              <ul>
                <li>
                  <div className="icon">
                    <i className="ti-location-pin"></i>
                  </div>
                  <h5>Our Location</h5>
                  <p>
                    Melland High School, Gorton Education Village <br />
                    Gorton , Manchester, M18 7DT
                  </p>
                </li>
                <li>
                  <div className="icon">
                    <i className="ti-mobile"></i>
                  </div>
                  <h5>Phone</h5>
                  <p>{state?.siteSettings?.PrimaryContactNumber}</p>
                </li>
                <li>
                  <div className="icon">
                    <i className="ti-email"></i>
                  </div>
                  <h5>Email</h5>
                  <p>{state?.siteSettings?.PrimaryContactEmail}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-lg-6 col-12">
            <div className="contact-form-area">
              <div className="section-title-s3 section-title-s5">
                <h2>Quick Contact Form</h2>
              </div>
              <div className="contact-form">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactpage;
