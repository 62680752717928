import React from 'react';
import { Link } from 'react-router-dom';
import event1 from '../../images/event/1.jpg';
import { imagePath } from '../../config';

const NewsTile = ({ news }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className='event-item'>
      <div className='event-img'>
        <img src={`${imagePath}/storage/uploads${news.image.path}`} alt='' />
      </div>
      <div className='event-text'>
        <div className='event-right'>
          <h2>
            <Link onClick={ClickHandler} to={`/blog-details/${news._id}`}>
              {news.title}
            </Link>
          </h2>
          <div dangerouslySetInnerHTML={{ __html: news.excerpt }} />
          <Link onClick={ClickHandler} to={`/blog-details/${news._id}`}>
            <span>Click here to read more...</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsTile;
