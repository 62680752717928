import React, { useContext, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import TransactionHistory from './TransactionHistory';
import CountDown from '../CountDown';
import { AppContext } from '../../contexts/AppContext';
import Ticket from './Ticket';
import { api, apiToken } from '../../config';
import { Link } from 'react-router-dom';

import './style.css';
import MyTickets from './MyTickets';

const CaseSingle = (props) => {
  const [store, setStore] = useContext(AppContext);
  const { auth } = store;
  let userDataFetched = false;

  const getMember = async (id) => {
    const memListData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { _id: id, delete: false },
        fields: {
          fullName: 1,
          paymentStatus: 1,
          email: 1,
          membership: 1,
          memberId: 1,
          status: 1,
          lastSubscriptionRenewedYear: 1,
          role: 1,
          _id: 1,
        },
      }),
    });
    const members = await memListData.json();
    userDataFetched = true;
    if (members.entries.length > 0) {
      setStore((prevStore) => ({
        ...prevStore,
        auth: members.entries[0],
      }));
    } else {
      window.location.href = `${window.location.origin}/logout`;
    }
  };

  useEffect(() => {
    if (auth && !userDataFetched) {
      getMember(auth._id);
    }
  }, [auth]);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className='tp-case-details-area section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col col-12'>
            {auth && (
              <div className='tp-blog-sidebar'>
                <div className='widget search-widget'>
                  <h3>
                    Account Details{' '}
                    <span>
                      <Link to='/amend-account' className='theme-btn amend-btn'>
                        <i className='fa fa-pencil'></i>EDIT
                      </Link>
                    </span>
                  </h3>

                  <div className='row'>
                    <div className='col col-12 col-md-4 mem-info'>
                      <div className='lineRow'>
                        Member ID:{' '}
                        {auth.memberId ? (
                          <Chip label={`MMA${auth.memberId}`} color='primary' />
                        ) : (
                          <Chip label='Not Generated' color='secondary' />
                        )}
                      </div>
                      <div className='lineRow'>
                        Account Status:{' '}
                        {auth.status ? (
                          <Chip label='Active' color='primary' />
                        ) : (
                          <Chip label='Suspended' color='secondary' />
                        )}
                      </div>
                      <div className='lineRow'>
                        Registration Payment:{' '}
                        {auth.paymentStatus ? (
                          <Chip label='Paid' color='primary' />
                        ) : (
                          <Chip label='Not Paid' color='secondary' />
                        )}
                      </div>
                      <div className='lineRow'>
                        Membership Type: <Chip label={auth.membership} />
                      </div>
                      <div className='lineRow'>
                        Email: <Chip label={auth.email} className='emailChip' />
                      </div>
                      <br />
                    </div>
                    <div
                      className='col col-12 col-md-3'
                      style={{ textAlign: 'center' }}
                    >
                      {/* {auth.lastSubscriptionRenewedYear && (
                        <CountDown
                          year={parseInt(auth.lastSubscriptionRenewedYear)}
                        />
                      )} */}
                      <span>{`Last Renewal: ${auth.lastSubscriptionRenewedYear}`}</span>
                      <br />
                      <span
                        style={{ color: 'red' }}
                      >{`Next Renewal: Before Sep 30, ${
                        parseInt(auth.lastSubscriptionRenewedYear) + 1
                      }`}</span>
                    </div>
                    <div className='col col-12 col-md-5 mem-ticket'>
                      <Ticket
                        subTitle={auth.membership}
                        title='Renew Subscription'
                        date={`Before: September 30, ${new Date().getFullYear()}`}
                        email={auth.email}
                        memberId={auth._id}
                        membershipType={auth.membership}
                        qty={1}
                        memberEmail={auth.email}
                        memberName={auth.fullName}
                        lastRenewed={auth.lastSubscriptionRenewedYear}
                      />
                    </div>
                  </div>
                </div>
                <MyTickets memberId={auth._id} />
                <TransactionHistory memberId={auth._id} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseSingle;
