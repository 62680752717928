import React from 'react';
import { Link } from 'react-router-dom';
import VideoModal from '../../components/ModalVideo';
import abimg4 from '../../images/shape/shape2.png';
import { imagePath } from '../../config';
const About2 = ({ content }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className='about-area section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-md-12  grid col-12'>
            <div className='video-area'>
              <img src={`${imagePath}/${content.Image.path}`} alt='' />
              <div className='entry-media video-holder video-btn2'>
                <VideoModal videoId={content.YoutubeVideoId} />
              </div>
            </div>
          </div>
          <div className='col-lg-8 grid  col-md-12 col-12'>
            <div className='about-text'>
              <div className='section-title'>
                <div className='thumb-text'>
                  <span>{content.preTitle || 'ABOUT US'}</span>
                </div>
                <h2>{content.Title}</h2>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.Description,
                }}
              ></div>
            </div>
          </div>
        </div>
        {content.Description2 && <div className='row'>
          <div className='col-lg-12 grid  col-md-12 col-12'>
            <div className='about-text'>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.Description2,
                }}
              ></div>
            </div>
          </div>
        </div>}
      </div>
      <div className='ab-shape'>
        <img src={abimg4} alt='' />
      </div>
    </div>
  );
};

export default About2;
