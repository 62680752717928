import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { AppContext } from '../../contexts/AppContext';
import { api, apiToken } from '../../config';
import logo from '../../images/logo/logo.png';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '90%',
    margin: 'auto',
    marginTop: '50px',
  },
  paper: {
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    paddingTop: '50px',
    '@media print': {
      width: '90%',
      maxWidth: '100%',
      height: '85%',
    },
    '@media screen and (max-width: 992px)': {
      overflow: 'scroll',
      height: '78vh',
      marginTop: '170px',
    },
  },
  text: {
    color: '#000',
  },
  para: {
    color: '#000',
    fontSize: '14px',
  },
  declarationMarks: {
    fontSize: '16px',
    marginRight: '5px',
    color: 'red',
  },
  printBtn: {
    background: '#d10000',
    color: '#fff',
    fontWeight: '600',
    textTransform: 'uppercase',
    border: 'none',
    padding: '5px 15px',
    borderRadius: '5px',
    '@media print': {
      display: 'none',
    },
  },
}));

const RegPay = (props) => {
  const classes = useStyles();

  const memId = props.match.params.id;
  const [store, setStore] = useContext(AppContext);
  const { siteSettings } = store;
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({
    regFee: null,
    memFee: null,
    total: null,
    productId: null,
  });
  const [member, setMember] = useState(null);

  const getMember = async (id) => {
    const memListData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { _id: memId, delete: false },
      }),
    });
    const members = await memListData.json();
    if (members.entries.length > 0) {
      setMember(members.entries[0]);
    }
  };

  useEffect(() => {
    if (store.siteSettings) {
      getMember();
    }
  }, [store.siteSettings]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className={classes.wrapper}>
      <img src={logo} width='200' />
      {member && (
        <div className={classes.paper}>
          <h2 id='transition-modal-title'>Membership Application</h2>
          <br />
          <table class='table'>
            <tbody>
              <tr>
                <td>Membership Type</td>
                <td>{member.membership}</td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>
                  {member.title}. {member.fullName}
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{member.email}</td>
              </tr>
              <tr>
                <td>Mobile</td>
                <td>{member.mobile}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>
                  {member.address}, {member.city}
                  <br />
                  {member.postcode}, {member.county}
                </td>
              </tr>
              {member.membership === 'Family' && (
                <>
                  <tr>
                    <td>Spouse Name</td>
                    <td>
                      {member.spouseTitle}. {member.spouseName}
                    </td>
                  </tr>
                  <tr>
                    <td>Spouse Email</td>
                    <td>{member.spouseEmail}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {member.membership === 'Family' && (
            <>
              <h3>Children ({member.childCount})</h3>
              <table class='table'>
                <tbody>
                  {Array.apply(null, {
                    length: parseInt(member.childCount),
                  }).map((e, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{member[`childName${i + 1}`]}</td>
                      <td>{member[`childYear${i + 1}`]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          <table className='table'>
            <tr>
              <td>
                <strong>Declaration</strong>
                <p className={classes.para}>
                  <span className={classes.declarationMarks}>
                    {member.agreeTerms === true ? (
                      <i
                        className='fa fa-check-square'
                        style={{ color: '#3f9969' }}
                        aria-hidden='true'
                      ></i>
                    ) : (
                      <i
                        className='fa fa-window-close'
                        style={{ color: 'red' }}
                        aria-hidden='true'
                      ></i>
                    )}
                  </span>
                  I declare that all information contained in this form is true,
                  correct to the best of my knowledge, and accept the rules and
                  regulations of MMA based on the constitution (available on MMA
                  Website www.mmaonline.org.uk)
                </p>
                <p className={classes.para}>
                  <span className={classes.declarationMarks}>
                    {member.giftAidConcent === true ? (
                      <i
                        className='fa fa-check-square'
                        style={{ color: '#3f9969' }}
                        aria-hidden='true'
                      ></i>
                    ) : (
                      <i
                        className='fa fa-window-close'
                        style={{ color: 'red' }}
                        aria-hidden='true'
                      ></i>
                    )}
                  </span>
                  Gift Aid - (Gift Aid is a scheme that allows charities to
                  reclaim tax on a donation made by a UK taxpayer, which means
                  that MMA can claim back from the HMRC 25p for every £1 you
                  donate) I here by declare that all donations I make on or
                  after the date of this declaration to Manchester Malayalee
                  Association are to be treated as gift aid donations.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Child photo / video consent</strong>
                <p className={classes.para}>
                  <span className={classes.declarationMarks}>
                    {member.childPhotoConcent === true ? (
                      <i
                        className='fa fa-check-square'
                        style={{ color: '#3f9969' }}
                        aria-hidden='true'
                      ></i>
                    ) : (
                      <i
                        className='fa fa-window-close'
                        style={{ color: 'red' }}
                        aria-hidden='true'
                      ></i>
                    )}
                  </span>
                  I give permission to take photographs and / or video of my
                  child.
                </p>
                <p className={classes.para}>
                  <span className={classes.declarationMarks}>
                    {member.childPhotoShareConcent === true ? (
                      <i
                        className='fa fa-check-square'
                        style={{ color: '#3f9969' }}
                        aria-hidden='true'
                      ></i>
                    ) : (
                      <i
                        className='fa fa-window-close'
                        style={{ color: 'red' }}
                        aria-hidden='true'
                      ></i>
                    )}
                  </span>
                  I grant full rights to use the images resulting from the
                  photography/video filming, and any reproductions or
                  adaptations of the images for fundraising, publicity or other
                  purposes to help achieve MMA’s aims. This might include (but
                  is not limited to), the right to use them in their printed and
                  online publicity, social media, press releases and funding
                  applications.
                </p>
              </td>
            </tr>
          </table>
          <button className={classes.printBtn} onClick={handlePrint}>
            Print <i class='fa fa-print' aria-hidden='true'></i>
          </button>
        </div>
      )}
      {!member && (
        <>
          <h2>Account Not Found</h2>
        </>
      )}
    </div>
  );
};

export default RegPay;
