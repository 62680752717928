import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Link } from 'react-router-dom';
import ScannedTicket from './ScannedTicket';
import './style.css';

const Scanner = (props) => {
  const [data, setData] = useState(null);
  // const [data, setData] = useState('b256764c33646465f50001c7');
  const [startScan, setStartScan] = useState(false);

  return (
    <div className='camWrapper'>
      {!data && (
        <>
          <div className='camBox'>
            {startScan && (
              <QrReader
                constraints={{
                  facingMode: 'environment',
                }}
                onResult={(result, error) => {
                  if (!!result) {
                    setData(result?.text);
                    setStartScan(false);
                  }
                }}
                style={{ width: '100%' }}
              />
            )}
          </div>
          <div className='scan-btn'>
            <button
              className='btn btn-success'
              onClick={() => setStartScan(true)}
            >
              START SCAN
            </button>
            <br />
            <br />
            <Link to='/home'>Go To Home</Link>
          </div>
        </>
      )}
      {data && <ScannedTicket data={data} setData={setData} />}
    </div>
  );
};

export default Scanner;
