import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const HeaderTopbar = ({ auth, siteSettings }) => {
  return (
    <div className='topbar'>
      <div className='container'>
        <div className='row'>
          <div className='col col-md-6 col-sm-12 col-12'>
            <div className='contact-intro'>
              <ul>
                <li>
                  <i className='fi flaticon-call'></i>
                  {siteSettings && siteSettings.PrimaryContactNumber}
                </li>
                <li>
                  <i className='fi flaticon-envelope'></i>{' '}
                  {siteSettings && siteSettings.PrimaryContactEmail}
                </li>
                <li className='socialMedia'>
                  <a
                    href='https://www.facebook.com/ManchesterMalayalee'
                    target='_blank'
                  >
                    <i class='fa fa-facebook-square'></i>
                  </a>
                </li>
                <li className='socialMedia'>
                  <a
                    href='https://www.youtube.com/user/manchestermalayali'
                    target='_blank'
                  >
                    <i class='fa fa-youtube-play'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col col-md-6 col-sm-12 col-12 d-none d-lg-block d-xl-block'>
            <div className='contact-info'>
              {auth && auth.status === true ? (
                <ul>
                  <li>
                    <Link to='/mem-account'>My Account</Link>
                  </li>
                  <li>
                    <Link to='/logout'>Logout</Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link to='/login'>Login</Link>
                  </li>
                  <li>
                    <Link to='/signup'>Sign Up</Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
