import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import BookTicketForm from './BookTicketForm';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const BookTicket = () => {
  return (
    <Fragment>
      <Navbar />
      <BookTicketForm />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BookTicket;
