import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import featuresimg from '../../images/features/img-1.png';
import featuresimg2 from '../../images/features/img-2.png';
import featuresimg3 from '../../images/features/img-3.png';
import featuresimg4 from '../../images/features/img-4.png';
import './style.css';
import { AppContext } from '../../contexts/AppContext';
import { imagePath } from '../../config';

const Features = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [state, setState] = useContext(AppContext);
  return (
    <div className='features-area'>
      {state.siteSettings && (
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-12'>
              <div className='features-text'>
                <div className='section-title'>
                  <div className='thumb-text'>
                    <span>WATCH</span>
                  </div>
                </div>
                <h2>{state.siteSettings.UpComingEventVideoTitle}</h2>
                <div className='home-video-player'>
                  {/* <iframe
                  width='1280'
                  height='704'
                  style={{ '--aspect-ratio': '704 / 1280' }}
                  src='https://www.youtube.com/embed/ozuO_lBuhns?showinfo=0&rel=0'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen
                ></iframe> */}

                  <video __idm_id__='105365505' controls>
                    <source
                      src={`${imagePath}/storage/uploads${state.siteSettings.UpComingEventVideo.path}`}
                      type='video/mp4'
                    />{' '}
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Features;
