import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api, apiToken } from '../../config';
import './pay-status-style.scss';

const TicketBookingSuccess = (props) => {
  const ticketId = props.match.params.ticketId;
  const memId = props.match.params.memId;
  const [loading, setLoading] = useState(true);
  const updateStatus = async () => {
    const memData = await fetch(`${api}/collections/save/tickets`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        data: {
          _id: ticketId,
          paymentStatus: true,
        },
      }),
    });
    const members = await memData.json();
    setLoading(false);
  };

  useEffect(() => {
    updateStatus();
  }, []);

  return (
    <div className='wrapper success-page'>
      <h2>Payment Success</h2>
      <p>You can see the ticket in My Tickets page.</p>
      <Link to='/mem-account' className='btn' disabled={loading}>
        {loading ? 'Loading...' : 'Done'}
      </Link>
    </div>
  );
};

export default TicketBookingSuccess;
