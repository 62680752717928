import React, { Fragment, useContext } from 'react';
import Navbar from '../../components/Navbar';
import GalleryDetails from '../../components/GalleryDetails';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import PageTitle from '../../components/pagetitle';
import { AppContext } from '../../contexts/AppContext';

const GalleryDetailsPage = (props) => {
  const [store, setStore] = useContext(AppContext);
  return (
    <Fragment>
      <Navbar />
      <PageTitle
        pageTitle={store.galleryTitle && store.galleryTitle}
        pagesub={'photos'}
        parent={{ page: 'Gallery', link: '/gallery' }}
      />
      <GalleryDetails {...props} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default GalleryDetailsPage;
