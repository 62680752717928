import React, { useContext } from 'react';
import NewsTile from '../news/NewsTile';
import { AppContext } from '../../contexts/AppContext';

import './style.css';

const EventSingle = (props) => {
  const [store, setStore] = useContext(AppContext);
  return (
    <div className='wpo-event-details-area section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-12'>
            {store.news && store.news.map((item) => <NewsTile news={item} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSingle;
