import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken } from '../../config';

import './style.css';

const EventCalendar = (props) => {
  const [store, setStore] = useContext(AppContext);

  const getEvents = async () => {
    const dataRes = await fetch(`${api}/collections/get/eventCalender`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: 1 },
      }),
    });
    const eventCalendar = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      events: [...eventCalendar.entries],
    }));
  };

  useEffect(() => {
    if (!store.events) {
      getEvents();
    }
  }, [store.events]);
  return (
    <div className='event-area section-padding event-calendar '>
      <div className='container'>
        <div className='row'>
          {store.events &&
            store.events.map((event) => (
              <div className='col-lg-12'>
                <Link to={event.url}>
                  <div className='event-item'>
                    <div className='event-text'>
                      <div className='event-left'>
                        <div className='event-l-text'>
                          <span>{event.Month}</span>
                          <h4>{event.Date}</h4>
                        </div>
                      </div>
                      <div className='event-right'>
                        <h2>{event.Name}</h2>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EventCalendar;
