import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import WomensDayTicketList from "../../components/Admin/WomensDayTicketList";

const WomensdayList = () => {
  return (
    <Fragment>
      <Navbar />
      <WomensDayTicketList />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default WomensdayList;
