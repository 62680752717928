import React from 'react';

import GallerySection from '../gallerySection';
import './style.css';

const Gallery = () => {
  return (
    <div className='gallery-page'>
      <GallerySection addclass='v2' />
    </div>
  );
};

export default Gallery;
