import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { api, apiToken } from '../../config';
import { timeFormat } from '../../utility';
import './thistoryStyle.scss';

const TransactionHistory = ({ memberId }) => {
  const [transactions, setTransactions] = useState(null);

  const getTransactions = async (id) => {
    const transaction = await fetch(
      `${api}/collections/get/membertransactions`,
      {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          sort: { _created: -1 },
          filter: { status: 'success', userId: id },
        }),
      }
    );
    const tsHistory = await transaction.json();
    console.log(tsHistory);
    setTransactions(tsHistory.entries);
  };
  useEffect(() => {
    getTransactions(memberId);
  }, []);

  return (
    <div className='widget recent-post-widget transaction-wrapper'>
      <h3>Transaction History</h3>
      {transactions && transactions.length > 0 ? (
        transactions.map((item) => (
          <div className='posts transaction-list' key={item._id}>
            <div className='post '>
              <div className='details'>
                <h5>
                  {item.transactionTitle} (
                  {`£${item.transactionAmount} X ${item.qty}`})
                </h5>
                <span className='date'>{timeFormat(item._created)}</span>
                <Chip
                  label={item.status}
                  color={
                    item.status === 'pending'
                      ? 'default'
                      : item.status === 'failed'
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </div>
              <div className='price-holder'>
                <h2>£{item.transactionAmount * item.qty}</h2>
              </div>
            </div>
          </div>
        ))
      ) : (
        <span>
          <br />
          You have no transactions!
        </span>
      )}
    </div>
  );
};

export default TransactionHistory;
