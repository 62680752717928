import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Homepage from "../HomePage";
import AboutPage from "../AboutPage";
import SchoolPage from "../SchoolPage";
import EventPage from "../EventPage";
import Blog from "../Blog";
import VolunteerPage from "../volunteerPage";
import GalleryPage from "../GalleryPage";
import GalleryDetailsPage from "../GalleryDetailsPage";
import BlogDetails from "../BlogDetails";
import ErrorPage from "../ErrorPage";
import ContactPage from "../ContactPage";
import LoginPage from "../LoginPage";
import SignUpPage from "../SignUpPage";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ForgotPassword/ResetPassword";
import RegConfirmation from "../RegConfirmation";
import MemAccount from "../MemAccount";
import AmendAccount from "../AmendAccount";
import BookTicket from "../BookTicket";
import Documents from "../Documents";
import ProtectedRoute from "./ProtectedRoute";
import Logout from "../Logout";
import PayCancel from "../payment/PayCancel";
import PaySuccess from "../payment/PaySuccess";
import RegPaySuccess from "../payment/RegPaySuccess";
import UpgradePaySuccess from "../payment/UpgradePaySuccess";
import TicketPaySuccess from "../BookTicket/TicketPaySuccess";
import TicketBookingSuccess from "../BookTicket/TicketBookingSuccess";
import RegPay from "../payment/RegPay";
import MemberDetails from "../Accounts/MemberDetails";
import Accounts from "../Accounts";
import RenewalList from "../Accounts/RenewalList";
import TicketList from "../Accounts/TicketList";
import QrScanner from "../QrScanner";
import BookWomensDay from "../BookWomensDay";
import WomensDayTicketPaySuccess from "../BookWomensDay/WomensDayTicketPaySuccess";
import WomensDayPaymentFailed from "../BookWomensDay/WomensDayPaymentFailed";
import WomensdayList from "../Accounts/WomensdayList";

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/home" component={Homepage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/school" component={SchoolPage} />
          <Route path="/event" component={EventPage} />
          <Route path="/office-bearers" component={VolunteerPage} />
          <Route path="/gallery" component={GalleryPage} exact />
          <Route path="/gallery/:id" component={GalleryDetailsPage} />
          <Route path="/blog" component={Blog} />
          <Route path="/blog-details/:id" component={BlogDetails} />
          <Route path="/404" component={ErrorPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/reset-account-password/:id/:randomKey"
            component={ResetPassword}
          />
          <Route path="/confirmation" component={RegConfirmation} />
          <Route path="/logout" component={Logout} />
          <ProtectedRoute path="/mem-account" component={MemAccount} />
          <ProtectedRoute path="/documents" component={Documents} />
          <ProtectedRoute path="/book-ticket" component={BookTicket} />
          <ProtectedRoute path="/book-womensday" component={BookWomensDay} />
          <Route
            path="/womansday-payment-success/:ticketId"
            component={WomensDayTicketPaySuccess}
          />
          <Route
            path="/womansday-payment-cancel/:ticketId"
            component={WomensDayPaymentFailed}
          />
          <Route
            path="/ticket-payment-success/:ticketId/:transactionId/:memId"
            component={TicketPaySuccess}
          />
          <Route
            path="/ticket-success/:ticketId/:memId"
            component={TicketBookingSuccess}
          />
          <Route
            path="/reg-payment-success/:id/:memId"
            component={RegPaySuccess}
          />
          <Route
            path="/upgrade-pay-success/:id/:memId"
            component={UpgradePaySuccess}
          />
          {/* For Subscription */}
          <Route path="/payment-success/:id/:memId" component={PaySuccess} />
          <Route path="/payment-cancel/:id" component={PayCancel} />
          <Route path="/payment-reg-fee/:id" component={RegPay} />
          <Route path="/member-details/:id" component={MemberDetails} />
          <ProtectedRoute path="/amend-account" component={AmendAccount} />
          <ProtectedRoute path="/accounts" component={Accounts} />
          <ProtectedRoute path="/deleted-members" component={Accounts} />
          <ProtectedRoute path="/mem-renewals" component={RenewalList} />
          <ProtectedRoute path="/mem-events-pay" component={TicketList} />
          <ProtectedRoute path="/manage-womensday" component={WomensdayList} />
          <ProtectedRoute path="/scan-qr-code" component={QrScanner} />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
