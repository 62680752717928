import React, { useContext, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import hero4 from '../../images/shape/shape.png';
import { AppContext } from '../../contexts/AppContext';
import { imagePath } from '../../config';

import './style.css';

const Hero = () => {
  var settings = {
    dots: true,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };
  const [state, setState] = useContext(AppContext);
  const [showRegistrationBtn, setShowRegistrationBtn] = useState(false);
  return (
    <section className='hero-slider hero-style-1'>
      <Slider {...settings}>
        {state.homeSlider &&
          state.homeSlider.map((slide) => (
            <div className='slide'>
              <div
                className='slide-inner'
                style={{
                  backgroundImage: `url(${imagePath}/${slide.BannerImage.path})`,
                }}
              >
                <div className='container'>
                  <div className='slide-thumb'>
                    <span>{slide.SmallText}</span>
                  </div>
                  <div className='slide-title'>
                    <h2>{slide.Title}</h2>
                  </div>
                  <div data-swiper-parallax='400' className='slide-text'>
                    <p>{slide.Description}</p>
                  </div>
                  {showRegistrationBtn && (
                    <div className='slide-btns'>
                      <Link to='/signup' className='theme-btn'>
                        Get Membership
                        <i
                          className='fa fa-angle-double-right'
                          aria-hidden='true'
                        ></i>
                      </Link>
                    </div>
                  )}
                </div>
                <div className='slide-shape'>
                  <img src={hero4} alt='' />
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </section>
  );
};

export default Hero;
