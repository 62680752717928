import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { api, apiToken } from "../../config";
import "../payment/style.scss";
import { Button } from "@material-ui/core";
import { AppContext } from "../../contexts/AppContext";
import { loadStripe } from "@stripe/stripe-js";

const WomensDayPaymentFailed = (props) => {
  const ticketId = props.match.params.ticketId;

  const [store, setStore] = useContext(AppContext);
  const { siteSettings } = store;

  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    getPendingPayment(ticketId);
    if (store.siteSettings) {
      const today = new Date();
      var endDate = new Date(siteSettings.UpComingEventTicketLastBookingDate);
      setExpired(today > endDate);
    }
  }, [store.siteSettings]);

  const getPendingPayment = async (id) => {
    const data = await fetch(`${api}/collections/get/womensday`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        filter: { _id: ticketId, delete: false },
      }),
    });
    const ticketData = await data.json();
    if (ticketData.entries.length > 0) {
      setTicket(ticketData.entries[0]);
    }
  };

  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    }
    return stripePromise;
  };

  const redirectToCheckout = async (checkoutOptions) => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log(error);
  };

  const handleTryAgain = () => {
    let items = [
      {
        price: ticket.stripeProductId,
        quantity: 1,
      },
    ];
    const checkoutOptions = {
      lineItems: items,
      mode: "payment",
      successUrl: `${window.location.origin}/womansday-payment-success/${ticketId}`,
      cancelUrl: `${window.location.origin}/womansday-payment-cancel/${ticketId}`,
      customerEmail: ticket.email,
    };
    redirectToCheckout(checkoutOptions);
  };

  return (
    <div className="wrapper error-page">
      <h2>Payment Failed</h2>
      <p>
        Your payment is failed or canceled. You can see all payment history
        under My Account page. Please contact MMA if you are facing issues.
      </p>

      {ticket && (
        <Button
          fullWidth
          className="cBtn cBtnLarge cBtnTheme"
          type="button"
          onClick={handleTryAgain}
          disabled={loading || expired}
        >
          {expired
            ? "Booking Not Available"
            : loading
            ? "Please wait..."
            : `Try again £${ticket.price}`}
        </Button>
      )}
      <br />
      <Link to="/" className="btn-error" disabled={loading}>
        Okay, Go Back
      </Link>
    </div>
  );
};

export default WomensDayPaymentFailed;
