import React, { useState, useContext, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { AppContext } from "../../contexts/AppContext";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { api, apiToken } from "../../config";
import { useHistory } from "react-router-dom";

import "./book-ticket.scss";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};

const WomensDayTicketForm = () => {
  const history = useHistory();
  const thisYear = new Date().getFullYear();
  const [store, setStore] = useContext(AppContext);
  const { auth, siteSettings } = store;
  const [product, setProduct] = useState({
    price: "",
    qty: 1,
    stripeId: "",
  });
  const [christmasProduct, setChristmasProduct] = useState([]);
  const initialState = {
    name: "",
    email: "",
    numberOfChildren: "0",
    year: thisYear,
  };
  const [value, setValue] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const payNow = async (ticketId) => {
    let items = [
      {
        price: product.stripeId,
        quantity: product.qty,
      },
    ];
    const checkoutOptions = {
      lineItems: items,
      mode: "payment",
      successUrl: `${window.location.origin}/womansday-payment-success/${ticketId}`,
      cancelUrl: `${window.location.origin}/womansday-payment-cancel/${ticketId}`,
      customerEmail: value.email,
    };
    redirectToCheckout(checkoutOptions);
  };

  const bookTicket = async () => {
    const dataToSend = { ...value };

    setLoading(true);

    const booking = await fetch(`${api}/collections/save/womensday`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        data: {
          ...dataToSend,
          price: product.price * product.qty,
          stripeProductId: product.stripeId,
        },
      }),
    });
    const bookingSaved = await booking.json();
    console.log(bookingSaved);
    if (parseInt(product.price) > 0) {
      payNow(bookingSaved._id);
    } else {
      history.push(`/womansday-payment-success/${bookingSaved._id}`);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      bookTicket();
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };

  useEffect(() => {
    if (store.siteSettings) {
      const today = new Date();
      var endDate = new Date(siteSettings.UpComingEventTicketLastBookingDate);
      setExpired(today > endDate);

      setProduct((prev) => ({
        ...prev,
        price: store.siteSettings.womensDayTicketPrice,
        stripeId: store.siteSettings.womensDayTicketStipeId,
      }));
    }
  }, [store.siteSettings]);

  const redirectToCheckout = async (checkoutOptions) => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log(error);
  };

  return (
    <Grid className="bookTicketForm">
      <h2>Women's Day celebration {thisYear}</h2>
      <h4>Read carefully & Book your ticket</h4>
      <br />
      <form onSubmit={submitForm}>
        <Grid className="loginForm signupForm">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder={`Your name`}
                    value={value[`name`]}
                    variant="outlined"
                    name={`name`}
                    label={`Name`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message(`name`, value[`name`], "required")}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="inputOutline"
                    fullWidth
                    placeholder={`Your email`}
                    value={value[`email`]}
                    variant="outlined"
                    name={`email`}
                    label={`Email`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(e) => changeHandler(e)}
                    onChange={(e) => changeHandler(e)}
                  />
                  {validator.message(`email`, value[`email`], "required")}
                </Grid>
                <Grid item xs={12}>
                  {/* <FormControl fullWidth variant="outlined">
                    <InputLabel id="title-label">
                      Select if you are bringing kids
                    </InputLabel>
                    <Select
                      className="inputOutline"
                      id="numberOfChildren"
                      labelId="numberOfChildren-label"
                      name="numberOfChildren"
                      value={value.numberOfChildren}
                      label="Number of children"
                      placeholder="Number of children"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Grid className="formFooter">
              <p style={{ color: "red" }}>
                Guests & Parents are not allowed in this ticket. <br />
                If you wish to bring guests or parents, please contact MMA (
                {siteSettings && siteSettings.womensDayContactNumber})
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className="formFooter">
              {siteSettings && (
                <Button
                  fullWidth
                  className="cBtn cBtnLarge cBtnTheme"
                  type="submit"
                  disabled={loading || expired}
                >
                  {expired
                    ? "Booking Not Available"
                    : loading
                    ? "Please wait..."
                    : `Book Now £${product.price * product.qty}`}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default WomensDayTicketForm;
