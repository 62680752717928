import React, { Component, useContext } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import './style.css';

const menus = [
  {
    id: 1,
    title: 'Home',
    link: '/',
  },

  {
    id: 2,
    title: 'About Us',
    link: '/about',
  },
  {
    id: 13,
    title: 'School',
    link: '/school',
  },
  {
    id: 3,
    title: 'Office Bearers',
    link: '/office-bearers',
  },
  {
    id: 4,
    title: 'Event Calender',
    link: '/event',
  },
  {
    id: 5,
    title: 'Gallery',
    link: '/gallery',
  },
  {
    id: 6,
    title: 'News',
    link: '/blog',
  },

  {
    id: 7,
    title: 'Contact Us',
    link: '/contact',
  },
];
const unAuthMenu = [
  {
    id: 13,
    title: 'Register',
    link: '/signup',
  },
  {
    id: 8,
    title: 'Login',
    link: '/login',
  },
];
const authMenu = [
  {
    id: 9,
    title: 'Documents',
    link: '/documents',
  },
  {
    id: 10,
    title: 'My Account',
    link: '/mem-account',
  },
  {
    id: 11,
    title: 'Logout',
    link: '/logout',
  },
];
const accountantMenu = [
  {
    id: 12,
    title: 'Dashboard',
    link: '/accounts',
  },
];

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  setIsOpen = (id) => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id,
    });
  };

  render() {
    const { isMenuShow, isOpen } = this.state;
    const { auth } = this.props;

    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
          <div className='close' onClick={this.menuHandler}>
            X
          </div>

          <ul className='responsivemenu'>
            {menus.map((item) => (
              <li key={item.id}>
                <Link to={item.link}>{item.title}</Link>
              </li>
            ))}
            {auth && auth.status
              ? authMenu.map((item) => {
                  return (
                    <li key={item.id}>
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  );
                })
              : unAuthMenu.map((item) => {
                  return (
                    <li key={item.id}>
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  );
                })}

            {auth &&
              auth.role === 'accountant' &&
              accountantMenu.map((item) => {
                return (
                  <li key={item.id}>
                    <Link to={item.link}>{item.title}</Link>
                  </li>
                );
              })}
          </ul>

          <div className='socialMedia'>
            Follow us on <br />
            <a
              href='https://www.facebook.com/ManchesterMalayalee'
              target='_blank'
            >
              <i class='fa fa-facebook-square'></i>
            </a>
            <a
              href='https://www.youtube.com/user/manchestermalayali'
              target='_blank'
            >
              <i class='fa fa-youtube-play'></i>
            </a>
          </div>
        </div>

        <div className='showmenu' onClick={this.menuHandler}>
          <i className='fa fa-bars' aria-hidden='true'></i>
        </div>
      </div>
    );
  }
}
