import React, { createContext, useState } from 'react';
export const AppContext = createContext();

export const AppProvider = (props) => {
  const { children } = props;
  const [store, setStore] = useState({
    siteSettings: null,
    homeSlider: null,
    aboutUs: null,
    events: null,
    officeBearers: null,
    officeBearersHistory: null,
    gallery: null,
    galleryImages: null,
    news: null,
    auth: null,
    documents: null,
  });
  return (
    <AppContext.Provider value={[store, setStore]}>
      {props.children}
    </AppContext.Provider>
  );
};
