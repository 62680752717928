import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';
import img3 from '../../images/features/img-3.png';

import './style.scss';

const RegConfirmation = (props) => {
  return (
    <Grid className='loginWrapper'>
      <Grid className='loginForm signupForm' style={{ textAlign: 'center' }}>
        <img src={img3} alt='' />
        <h2>Application Under Review</h2>
        <p>
          Thanks! We've received your request for the MMA membership. We will
          review your application and will let you know when it's done.
        </p>

        <h2>Keep an eye on your email.</h2>
        <p>
          Be sure to watch your registered email address as we may request more
          information to facilitate the review process in the coming days (be
          sure to check your spam folder as well).
        </p>
        <p>
          You'll receive an email with a payment link when the review is
          complete.{' '}
        </p>
        <Link to='/home' className='theme-btn'>
          Back To Home
          <i className='fa fa-angle-double-left' aria-hidden='true'></i>
        </Link>
      </Grid>
    </Grid>
  );
};

export default withRouter(RegConfirmation);
