import React, { useContext } from 'react';
import event4 from '../../images/event/1.png';
import event5 from '../../images/event/2.png';
import NewsTile from '../news/NewsTile';
import { AppContext } from '../../contexts/AppContext';

import './style.css';

const HomeNews = (props) => {
  const [store, setStore] = useContext(AppContext);
  return (
    <div className='event-area section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 offset-lg-3'>
            <div className='section-title section-title2 text-center'>
              <div className='thumb-text'>
                <span>NEWS & EVENTS</span>
              </div>
              <h2>Latest From Our News</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            {store.news &&
              store.news.slice(0, 5).map((item) => <NewsTile news={item} />)}
          </div>
        </div>
      </div>
      <div className='shape1'>
        <img src={event4} alt='' />
      </div>
      <div className='shape2'>
        <img src={event5} alt='' />
      </div>
    </div>
  );
};

export default HomeNews;
