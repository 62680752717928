import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import EditAccount from '../../components/EditAccount';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const AmendAccount = (props) => {
  return (
    <Fragment>
      <Navbar />
      <EditAccount {...props} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AmendAccount;
