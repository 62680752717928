import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import Hero from '../../components/hero';
import About from '../../components/about';
import Features from '../../components/Features';
import CtaSection from '../../components/cta';
import HomeNews from '../../components/homeNews';
import Footer from '../../components/footer';

import Scrollbar from '../../components/scrollbar';

const HomePage = () => {
  return (
    <Fragment>
      <Navbar />
      <Hero />
      <About />
      <Features />
      <HomeNews />
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
