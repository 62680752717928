import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import './members.scss';
import { timeFormat } from '../../utility';

const TicketWrapper = ({ search, tickets }) => {
  const defaultMaterialTheme = createTheme();
  const { event, year } = search;
  const [ticketListData, setTicketListData] = useState(null);

  const columns = [
    // {
    //   title: 'Date',
    //   field: '_created',
    //   render: (rowData) => timeFormat(rowData._created, false),
    //   defaultSort: 'desc',
    // },
    { title: 'Date', field: '_created' },
    { title: 'Type', field: 'membership' },
    { title: 'Email', field: 'email' },
    { title: 'Amount', field: 'ticketPrice' },
    { title: 'Ticket Used?', field: 'verificationStatus' },
    { title: 'Adult 1', field: 'adultName1' },
    { title: 'Adult 2', field: 'adultName2' },
    { title: 'Parent 1', field: 'parentName1' },
    { title: 'Parent 2', field: 'parentName2' },
    { title: 'Parent 3', field: 'parentName3' },
    { title: 'Parent 4', field: 'parentName4' },
    { title: 'Above13 Child1', field: 'above13Child1' },
    { title: 'Above13 Child2', field: 'above13Child2' },
    { title: 'Above13 Child3', field: 'above13Child3' },
    { title: 'Above13 Child4', field: 'above13Child4' },
    { title: 'Above13 Child5', field: 'above13Child5' },
    { title: 'Above5 Child1', field: 'above5Child1' },
    { title: 'Above5 Child2', field: 'above5Child2' },
    { title: 'Above5 Child3', field: 'above5Child3' },
    { title: 'Above5 Child4', field: 'above5Child4' },
    { title: 'Above5 Child5', field: 'above5Child5' },
    { title: 'Below5 Child1', field: 'below5Child1' },
    { title: 'Below5 Child2', field: 'below5Child2' },
    { title: 'Below5 Child3', field: 'below5Child3' },
  ];

  const tableOptions = {
    pageSizeOptions: [15, 50, 100, 250, 500],
    pageSize: 15,
    exportButton: true,
    exportAllData: true,
    actionsColumnIndex: -1,
    rowStyle: (rowData) =>
      rowData.verificationStatus === true ? { backgroundColor: '#f1f1f1' } : {},
  };

  useEffect(() => {
    if (tickets[event] && tickets[event].entries) {
      const formattedTickets = tickets[event].entries.map((item) => ({
        ...item,
        _created: timeFormat(item._created, false),
      }));
      setTicketListData(formattedTickets);
    } else {
      setTicketListData(null);
    }
  }, [event, year]);
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        {ticketListData && (
          <MaterialTable
            columns={columns}
            data={ticketListData}
            title='Tickets'
            options={tableOptions}
          />
        )}
      </ThemeProvider>
    </div>
  );
};

export default TicketWrapper;
