import React, { Fragment, useEffect, useContext } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Features from '../../components/Features';
import About2 from '../../components/about2';
import TeamSection from '../../components/team';
import Testimonial from '../../components/Testimonial';
import CounterSection from '../../components/counter';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken } from '../../config';

const CasePage = () => {
  const [store, setStore] = useContext(AppContext);

  const getAboutUs = async () => {
    const dataRes = await fetch(`${api}/singletons/get/aboutUs`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: 1 },
      }),
    });
    const aboutUs = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      aboutUs: { ...aboutUs },
    }));
  };

  useEffect(() => {
    if (!store.aboutUs) {
      getAboutUs();
    }
  }, [store.aboutUs]);

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={'About Us'} pagesub={'About'} />
      {store.aboutUs && <About2 content={store.aboutUs} />}
      {/* <Features />
      <TeamSection />
      <Testimonial />
      <CounterSection />*/}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default CasePage;
