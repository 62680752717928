import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api, apiToken } from '../../config';
import './style.scss';

const PaySuccess = (props) => {
  const transactionId = props.match.params.id;
  const memId = props.match.params.memId;
  const [loading, setLoading] = useState(true);
  const updateStatus = async () => {
    const transaction = await fetch(
      `${api}/collections/save/membertransactions`,
      {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          select: { userId: 1 },
          data: {
            _id: transactionId,
            status: 'success',
          },
        }),
      }
    );
    const transactionSaved = await transaction.json();
    // update member table
    const memData = await fetch(`${api}/collections/save/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        data: {
          _id: memId,
          lastSubscriptionRenewedYear: new Date().getFullYear(),
        },
      }),
    });
    const members = await memData.json();
    setLoading(false);
  };

  useEffect(() => {
    updateStatus();
  }, []);

  return (
    <div className='wrapper success-page'>
      <h2>Payment Success</h2>
      <p>You can see all payment history under My Account page.</p>
      <Link to='/mem-account' className='btn' disabled={loading}>
        {loading ? 'Loading...' : 'Done'}
      </Link>
    </div>
  );
};

export default PaySuccess;
