import React, { useEffect, useContext, useState } from "react";

import { api, apiToken } from "../../config";
import { AppContext } from "../../contexts/AppContext";
import InstallPrompt from "../InstallPrompt/InstallPrompt";
import CookieConcent from "../CookieConcent/CookieConcent";

const Layout = ({ children }) => {
  const [store, setStore] = useContext(AppContext);
  const [showInstallPromotion, setShowInstallPromotion] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [cookieAgreed, setCookieAgreed] = useState(null);

  const onAcceptCookie = () => {
    setCookieAgreed("true");
    localStorage.setItem("agreeCookie", "true");
  };

  const getSiteSettings = async () => {
    // GENERAL
    const dataRes = await fetch(`${api}/singletons/get/general`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        sort: { _created: 1 },
      }),
    });
    const siteSettings = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      siteSettings: { ...siteSettings },
    }));

    // IMAGE SLIDER
    const imageSlides = await fetch(`${api}/collections/get/homeBanner`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        sort: { _created: 1 },
      }),
    });
    const slides = await imageSlides.json();
    setStore((prevStore) => ({
      ...prevStore,
      homeSlider: [...slides.entries],
    }));

    // IMAGE SLIDER
    const newsData = await fetch(`${api}/collections/get/news`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: {
          published: true,
        },
      }),
    });
    const news = await newsData.json();
    setStore((prevStore) => ({
      ...prevStore,
      news: [...news.entries],
    }));
  };

  useEffect(() => {
    // get cookieConcent form localstorage
    setCookieAgreed(localStorage.getItem("agreeCookie"));

    getSiteSettings();

    // Initialize deferredPrompt for use later to show browser install prompt.
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
      setDeferredPrompt(e); // Stash the event so it can be triggered later.
      setShowInstallPromotion(true); // Update UI notify the user they can install the PWA
    });

    window.addEventListener("appinstalled", () => {
      setShowInstallPromotion(false); // Hide the app-provided install promotion
      setDeferredPrompt(null); // Clear the deferredPrompt so it can be garbage collected
    });

    if (!store.auth) {
      const lclAuth = localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth"))
        : null;
      setStore((prev) => ({
        ...prev,
        auth: lclAuth,
      }));
    }
  }, []);

  const handleCancelInstall = () => {
    setShowInstallPromotion(false);
  };
  const handleInstall = async () => {
    setShowInstallPromotion(false);
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    setDeferredPrompt(null);
  };
  return (
    <>
      {children}
      {cookieAgreed != "true" && <CookieConcent onAccept={onAcceptCookie} />}
      {/* {showInstallPromotion && (
        <InstallPrompt
          handleCancel={handleCancelInstall}
          handleInstall={handleInstall}
        />
      )} */}
    </>
  );
};

export default Layout;
