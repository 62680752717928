import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import './members.scss';
import { toast } from 'react-toastify';

const NonRenewedList = ({ members, paidMembers }) => {
  const defaultMaterialTheme = createTheme();
  const nonRenewedList = members.filter(
    // (mem) => !paidMembers.includes(mem._id)
    (mem) => mem.lastSubscriptionRenewedYear != new Date().getFullYear()
  );
  const tableOptions = {
    pageSizeOptions: [15, 50, 100, 250, 500],
    pageSize: 15,
    exportButton: true,
    exportAllData: true,
    actionsColumnIndex: -1,
  };
  const [failedRecipients, setFailedRecipients] = useState(null);
  const [showFailedRecipientModel, setShowFailedRecipientModel] =
    useState(false);

  const columns = [
    { title: 'Name', field: 'fullName' },
    { title: 'Membership Type', field: 'membership' },
    { title: 'Email', field: 'email' },
    { title: 'Mobile', field: 'mobile' },
    { title: 'Last Paid', field: 'lastSubscriptionRenewedYear' },
  ];

  const handleSendBulkMail = async () => {
    const recpList = nonRenewedList.map((mem) => mem.email).join(',');
    // send email
    const phpEmail = await fetch(
      `https://cockpit.mmaonline.org.uk/email/send-bulk-email.phps`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            subject: 'MMA - Membership',
            recipient: recpList,
            body: `Dear Member, <br/><br/>

            This is a kind reminder to pay the subscription fee for this year on or before <strong>30th September</strong>. <br/><br/>

            You can pay the subscription online. Please login to https://mmaonline.org.uk/ and go to <strong>My Account</strong> section. There you will be able to see the next renewal date and the link to do the payment. <br/><br/>

            Please contact Treasurer or Secretary if you have any question.<br/><br/>

            Kind regards,<br/>
            <b>Secretary<br/>
            Manchester Malayalee Association</b>
            `,
          },
        }),
      }
    );
    const res = await phpEmail.json();
    if (res.status === 'success') {
      toast.success(res.message);
    } else {
      let errMsg = res.message;
      if (res.result && res.result.length > 0) {
        const failedEmails = res.result.filter(
          (item) => item.status == 'failed'
        );
        errMsg = `${errMsg} (${failedEmails.length})`;
        if (failedEmails.length > 0) {
          setFailedRecipients(failedEmails);
          setShowFailedRecipientModel(true);
        }
      }
      toast.error(errMsg);
    }
  };

  const handleSendBulkMail2 = () => {
    setShowFailedRecipientModel(true);
    console.log(nonRenewedList);
    setFailedRecipients(nonRenewedList);
  };

  const hideFailedRecipientModel = (e) => {
    setShowFailedRecipientModel(false);
  };

  return (
    <div>
      <button
        className='iconButton'
        onClick={handleSendBulkMail2}
        style={{ border: 'none', marginBottom: '10px' }}
      >
        Send Notification To All
      </button>
      <ThemeProvider theme={defaultMaterialTheme}>
        {members && (
          <MaterialTable
            columns={columns}
            data={nonRenewedList}
            title={`Subscription Pending (${nonRenewedList.length})`}
            options={tableOptions}
          />
        )}
      </ThemeProvider>
      {showFailedRecipientModel && (
        <div className='failedRecipientModel'>
          <h2>Subscription pending recipients</h2>
          <p>
            Our system is experiencing technical difficulties to send bulk
            emails. Kindly use the provided recipient list and manually send
            emails.
          </p>
          <ul>
            {failedRecipients &&
              failedRecipients.map((failedMail) => <li>{failedMail.email}</li>)}
          </ul>
          <button
            className='iconButton'
            style={{ border: 'none', marginBottom: '10px' }}
            onClick={hideFailedRecipientModel}
          >
            CLOSE
          </button>
        </div>
      )}
    </div>
  );
};

export default NonRenewedList;
