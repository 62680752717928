import React, { useState } from 'react';

const PreviousTeams = ({ team }) => {
  const [show, setShow] = useState(false);
  return (
    <div className='col col-lg-8 col-12 prevOfficeBearers'>
      <div className='tp-case-details-wrap'>
        <div className='tp-case-details-text'>
          <div id='Description'>
            <div className='tp-case-content'>
              <div className='tp-case-text-top pre-teams-list'>
                <h2
                  onClick={() => setShow((prev) => !prev)}
                  style={{ cursor: 'pointer' }}
                >
                  {team.title}
                  {show ? (
                    <i class='fa fa-arrow-circle-down'></i>
                  ) : (
                    <i class='fa fa-arrow-circle-right'></i>
                  )}
                </h2>
                {show && (
                  <div
                    className='case-bb-text'
                    dangerouslySetInnerHTML={{ __html: team.content }}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousTeams;
