import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import { api, apiToken, privateKey } from '../../config';

import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

import './style.scss';

const ResetPassword = (props) => {
  const userId = props.match.params.id;
  const randomKey = props.match.params.randomKey;
  const initialState = {
    password: '',
    confirmPassword: '',
  };
  const [value, setValue] = useState(initialState);
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUserDetails = async () => {
    const uData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: {
          _id: userId,
          resetKey: randomKey,
        },
        fields: {
          email: 1,
          _id: 1,
        },
      }),
    });
    const res = await uData.json();
    if (res.entries.length > 0) {
      setUserDetails(res.entries[0]);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: 'errorMessage',
    })
  );

  const setNewPassword = async () => {
    setIsLoading(true);
    const hashDigest = sha256(value.password);
    const hmacDigest = Base64.stringify(hmacSHA512(hashDigest, privateKey));

    const uData = await fetch(`${api}/collections/save/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        data: {
          _id: userDetails._id,
          password: hmacDigest,
          resetKey: '',
        },
      }),
    });
    const user = await uData.json();

    // send email notification
    const phpEmail = await fetch(
      `https://cockpit.mmaonline.org.uk/email/send-email.php`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            subject: 'MMA - Password Updated!',
            recipient: userDetails.email,
            body: `We noticed that the password for your MMA account was recently changed. <br/>
            Please let us know, if this wasn't you.<br/><br/>
            Kind regards,<br/>
            <b>Secretary<br/>
            Manchester Malayalee Association</b>
            `,
          },
        }),
      }
    );
    const res = await phpEmail.json();

    setValue(initialState);
    validator.hideMessages();
    toast.success(
      'You have changed your password. Try login with new password.'
    );
    setIsLoading(false);
    props.history.push('/login');
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      await setNewPassword();
    } else {
      validator.showMessages();
      toast.error('Empty field is not allowed!');
    }
  };
  return (
    <Grid className='loginWrapper'>
      <Grid className='loginForm'>
        <h2>Forgot Password</h2>
        <p>Reset your account password</p>
        {userDetails ? (
          <form onSubmit={submitForm}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  className='inputOutline'
                  fullWidth
                  placeholder='New Password'
                  value={value.password}
                  variant='outlined'
                  name='password'
                  type='password'
                  label='New Password'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                />
                {validator.message('password', value.password, 'required')}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className='inputOutline'
                  fullWidth
                  placeholder='Confirm Password'
                  value={value.confirmPassword}
                  variant='outlined'
                  name='confirmPassword'
                  type='password'
                  label='Confirm Password'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                />
                {validator.message(
                  'confirmPassword',
                  value.confirmPassword,
                  `required|in:${value.password}`
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid className='formFooter'>
                  <Button
                    fullWidth
                    className='cBtn cBtnLarge cBtnTheme'
                    type='submit'
                    disabled={isLoading}
                  >
                    Change Password
                  </Button>
                </Grid>
                <p className='noteHelp'>
                  Already have an account?{' '}
                  <Link to='/login'>Return to Sign In</Link>
                </p>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid item xs={12}>
            <p className='noteHelp'>
              This link is invalid. <Link to='/login'>Return to Sign In</Link>
            </p>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withRouter(ResetPassword);
