import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import TicketLists from '../../components/Admin/TicketLists';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const TicketList = () => {
  return (
    <Fragment>
      <Navbar />
      <TicketLists />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default TicketList;
