import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { api, apiToken } from '../../config';
import { timeFormat } from '../../utility';
import QRModal from './QRModal';
import './thistoryStyle.scss';

const MyTickets = ({ memberId }) => {
  const [tickets, setTickets] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [ticket, setTicket] = React.useState(null);

  const handleOpen = (item) => {
    setOpen(true);
    setTicket(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getTickets = async (id) => {
    const transaction = await fetch(`${api}/collections/get/tickets`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { userId: id, verificationStatus: false, paymentStatus: true },
      }),
    });
    const tsHistory = await transaction.json();
    setTickets(tsHistory.entries);
  };
  useEffect(() => {
    getTickets(memberId);
  }, []);

  return (
    <div className='widget recent-post-widget transaction-wrapper'>
      <h3>My Tickets</h3>
      {tickets && tickets.length > 0 ? (
        tickets.map((item) => (
          <div className='posts transaction-list' key={item._id}>
            <div className='post '>
              <div className='details'>
                <h5>
                  {item.title}
                  <Chip label={`£${item.ticketPrice}`} color={'default'} />
                </h5>

                <span className='date'>{timeFormat(item._created)}</span>
                {/* <Chip
                  label={
                    item.paymentStatus
                      ? 'Paid'
                      : item.ticketPrice === '0'
                      ? 'Free'
                      : 'Not Paid'
                  }
                  color={'default'}
                /> */}
              </div>
              <div className='price-holder'>
                <button
                  className='show_qr_btn'
                  onClick={() => {
                    handleOpen(item);
                  }}
                >
                  Show QR Code
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <span>
          <br />
          You have no open tickets!
        </span>
      )}
      <QRModal handleClose={handleClose} open={open} ticket={ticket} />
    </div>
  );
};

export default MyTickets;
