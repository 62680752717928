import React from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AppProvider } from "../../contexts/AppContext";
import Layout from "../../components/Layout/Layout";

console.log("STAGE:", process.env.REACT_APP_STAGE);
const App = () => {
  return (
    <AppProvider>
      <Layout>
        <div className="App" id="scrool">
          <AllRoute />
          <ToastContainer />
        </div>
      </Layout>
    </AppProvider>
  );
};

export default App;
