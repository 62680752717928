import React, { useContext } from 'react';
import abimg4 from '../../images/shape/shape2.png';
import './style.css';
import { AppContext } from '../../contexts/AppContext';
import { imagePath } from '../../config';
import { Link } from 'react-router-dom';

const About = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [state, setState] = useContext(AppContext);
  return (
    <div className='about-area'>
      {state.siteSettings && (
        <>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-5 col-md-12  grid col-12'>
                <div className='about-cercle'>
                  <div className='about-img'>
                    <img
                      src={`${imagePath}/${state.siteSettings.UpComingEventImage.path}`}
                      alt=''
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-6 offset-lg-1 grid  col-md-12 col-12'>
                <div className='about-text'>
                  <div className='section-title'>
                    <div className='thumb-text'>
                      <span>UPCOMING EVENT</span>
                    </div>
                    <h2>{state.siteSettings.UpComingEventTitle}</h2>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: state.siteSettings.UpComingEventDescription,
                    }}
                  ></div>
                  <Link
                    to={state.siteSettings.UpComingEventBookingLink}
                    className='theme-btn'
                  >
                    {state.siteSettings.UpComingEventBtnText}
                    <i
                      className='fa fa-angle-double-right'
                      aria-hidden='true'
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='ab-shape'>
            <img src={abimg4} alt='' />
          </div>
        </>
      )}
    </div>
  );
};

export default About;
