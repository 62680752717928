import React, { useEffect, useContext } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken, imagePath } from '../../config';
import PreviousTeams from './PreviousTeams';

const TeamSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [store, setStore] = useContext(AppContext);

  const getOfficeBearers = async () => {
    const dataRes = await fetch(`${api}/collections/get/officeBearers`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { order: 1 },
      }),
    });
    const officeBearers = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      officeBearers: [...officeBearers.entries],
    }));
  };
  const getOldOfficeBearers = async () => {
    const dataRes = await fetch(`${api}/collections/get/officeBearersHistory`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
      }),
    });
    const officeBearersHistory = await dataRes.json();
    setStore((prevStore) => ({
      ...prevStore,
      officeBearersHistory: [...officeBearersHistory.entries],
    }));
  };

  useEffect(() => {
    if (!store.officeBearers) {
      getOfficeBearers();
      getOldOfficeBearers();
    }
  }, [store.officeBearers]);

  return (
    <div className={`volunteer-area section-padding ${props.addclass}`}>
      <div className='container'>
        <div className='volunteer-wrap'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-3'>
              <div className='section-title section-title2 text-center'>
                <h2>
                  {store.siteSettings &&
                    store.siteSettings.latestOfficeBearesTitle}
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            {store.officeBearers &&
              store.officeBearers.map((person) => (
                <div className='col col-lg-3 col-md-6 custom-grid col-12'>
                  <div className='volunteer-item'>
                    <div className='volunteer-img'>
                      <img src={`${imagePath}/${person.photo.path}`} alt='' />
                    </div>
                    <div className='volunteer-content'>
                      <h2>
                        <Link onClick={ClickHandler} to='/office-bearers'>
                          {person.name}
                        </Link>
                      </h2>
                      <span>{person.role}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <br />
          <div className='row'>
            {store.officeBearersHistory &&
              store.officeBearersHistory.map((team) => (
                <PreviousTeams team={team} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
