import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo/logo.png";
import n1 from "../../images/instragram/1.jpg";
import n2 from "../../images/instragram/2.jpg";
import n3 from "../../images/instragram/3.jpg";
import n4 from "../../images/instragram/4.jpg";
import n5 from "../../images/instragram/5.jpg";
import n6 from "../../images/instragram/6.jpg";
import "./style.css";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="tp-site-footer">
      <div className="tp-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12 copyright-footer">
              <p className="copyright">
                &copy; {new Date().getFullYear()} Manchester Malayalee
                Association. All rights reserved
              </p>
              <div className="techsa-wrapper">
                <span class="techsa-footer">
                  Developed &amp; Maintained by
                  <a
                    class="text-sky-400 font-bold flex items-center ml-0 lg:ml-4"
                    href="https://techsasolutions.com?source=mma"
                    target="_blank"
                  >
                    <img
                      src="/techsaicon.png"
                      alt="Techsa Solutions"
                      class="h-4"
                    />
                    <strong>
                      TECH<span class="text-pink-400">SA</span>
                    </strong>
                    <span class="text-sky-400 ml-1">SOLUTIONS</span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
