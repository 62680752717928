import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { api, apiToken } from '../../config';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GreenCheckbox from '../../main-component/SignUpPage/GreenCheckbox';
import Button from '@material-ui/core/Button';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';

import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }
  return stripePromise;
};

const EditAccount = (props) => {
  const [store, setStore] = useContext(AppContext);
  const { auth, siteSettings } = store;

  const [productDetails, setProductDetails] = useState({
    regFee: null,
    memFee: null,
    total: null,
    productId: null,
  });

  useEffect(() => {
    if (siteSettings) {
      const thisMonth = new Date().getMonth() + 1;
      const subscriptionFee =
        thisMonth > parseInt(siteSettings.freeSubscriptionAfterMonth)
          ? 0
          : parseInt(siteSettings.singleMembershipRenewFee);

      const productStripeId =
        thisMonth > parseInt(siteSettings.freeSubscriptionAfterMonth)
          ? siteSettings.singleMembershipOnlyRegProduct
          : siteSettings.singleMembershipRegProduct;

      setProductDetails({
        regFee: siteSettings.singleMembershipRegFee,
        memFee: subscriptionFee,
        total: parseInt(siteSettings.singleMembershipRegFee) + subscriptionFee,
        productId: productStripeId,
      });
    }
  }, [siteSettings]);

  const initialState = {
    address: '',
    childCount: '',
    childName1: '',
    childName2: '',
    childName3: '',
    childName4: '',
    childName5: '',
    childYear1: '',
    childYear2: '',
    childYear3: '',
    childYear4: '',
    childYear5: '',
    city: '',
    county: '',
    fullName: '',
    membership: '',
    mobile: '',
    postcode: '',
    spouseEmail: '',
    spouseMobile: '',
    spouseName: '',
    spouseTitle: '',
    title: '',
    childPhotoConcent: false,
    childPhotoShareConcent: false,
  };
  const [value, setValue] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [initialMembership, setInitialMembership] = useState('');

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const changeCheckboxHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.checked });
    validator.showMessages();
  };

  const convertToFamily = () => {
    console.log(value.lastSubscriptionRenewedYear, new Date().getFullYear());
    if (
      parseInt(value.lastSubscriptionRenewedYear) === new Date().getFullYear()
    ) {
      setValue((prev) => ({
        ...prev,
        membership: 'Family',
        childCount: '0',
      }));
    } else {
      toast.error('Please pay your subscription before adding family!');
    }
  };

  const getMember = async (id) => {
    const memListData = await fetch(`${api}/collections/get/userlist`, {
      headers: {
        'Cockpit-Token': apiToken,
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        sort: { _created: -1 },
        filter: { _id: id, delete: false },
        fields: {
          _id: 1,
          address: 1,
          childCount: 1,
          childName1: 1,
          childName2: 1,
          childName3: 1,
          childName4: 1,
          childName5: 1,
          childYear1: 1,
          childYear2: 1,
          childYear3: 1,
          childYear4: 1,
          childYear5: 1,
          city: 1,
          county: 1,
          fullName: 1,
          membership: 1,
          mobile: 1,
          postcode: 1,
          spouseEmail: 1,
          spouseMobile: 1,
          spouseName: 1,
          spouseTitle: 1,
          title: 1,
          childPhotoConcent: 1,
          childPhotoShareConcent: 1,
          email: 1,
          lastSubscriptionRenewedYear: 1,
        },
      }),
    });
    const members = await memListData.json();
    if (members.entries.length > 0) {
      setValue(members.entries[0]);
      setInitialMembership(members.entries[0].membership);
    } else {
      window.location.href = `${window.location.origin}/logout`;
    }
  };

  useEffect(() => {
    if (auth) {
      getMember(auth._id);
    } else {
      props.history.push('/mem-account');
    }
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setIsLoading(true);

      const uData = await fetch(`${api}/collections/save/userlist`, {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            ...value,
            membership: initialMembership,
          },
        }),
      });
      const user = await uData.json();
      validator.hideMessages();
      if (initialMembership === value.membership) {
        toast.success('Details Updated.');
        setIsLoading(false);
      } else {
        payNow();
      }
    } else {
      validator.showMessages();
      toast.error('Empty field is not allowed!');
    }
  };

  const redirectToCheckout = async (checkoutOptions) => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log(error);
  };

  const payNow = async () => {
    setIsLoading(true);
    const transaction = await fetch(
      `${api}/collections/save/membertransactions`,
      {
        headers: {
          'Cockpit-Token': apiToken,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          data: {
            userId: value._id,
            transactionTitle: `Convert to ${value.membership} `,
            transactionAmount: productDetails.total,
            year: new Date().getFullYear(),
            status: 'pending',
            striperef: productDetails.productId,
            qty: 1,
            membershipType: value.membership,
            memberName: value.fullName,
            memberEmail: value.email,
            paymentType: 'registration',
          },
        }),
      }
    );
    const transactionSaved = await transaction.json();

    const item = {
      price: productDetails.productId,
      quantity: 1,
    };

    const checkoutOptions = {
      lineItems: [item],
      mode: 'payment',
      successUrl: `${window.location.origin}/upgrade-pay-success/${transactionSaved._id}/${value._id}`,
      cancelUrl: `${window.location.origin}/payment-cancel/${transactionSaved._id}`,
      customerEmail: value.email,
      clientReferenceId: `${value._id}--${transactionSaved._id}`,
    };
    redirectToCheckout(checkoutOptions);
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: 'errorMessage',
    })
  );

  return (
    <div className='tp-case-details-area section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col col-12'>
            {auth && (
              <div className='tp-blog-sidebar'>
                <div className='widget search-widget'>
                  <h3>
                    Edit Account{' '}
                    <span>
                      <Link to='/mem-account' className='theme-btn amend-btn'>
                        <i className='fa fa-arrow-left'></i>BACK
                      </Link>
                    </span>
                  </h3>
                  <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant='outlined'>
                          <InputLabel id='title-label'>Title</InputLabel>
                          <Select
                            className='inputOutline'
                            id='title'
                            labelId='title-label'
                            name='title'
                            value={value.title}
                            label='Title'
                            placeholder='Title'
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                          >
                            <MenuItem value='Mr'>Mr</MenuItem>
                            <MenuItem value='Mrs'>Mrs</MenuItem>
                            <MenuItem value='Miss'>Miss</MenuItem>
                            <MenuItem value='Dr'>Dr</MenuItem>
                            <MenuItem value='Other'>Other</MenuItem>
                          </Select>
                          {validator.message('title', value.title, 'required')}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          className='inputOutline'
                          fullWidth
                          placeholder='Full Name'
                          value={value.fullName}
                          variant='outlined'
                          name='fullName'
                          label='Name'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        />
                        {validator.message(
                          'full name',
                          value.fullName,
                          'required'
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          className='inputOutline'
                          fullWidth
                          placeholder='Address Line 1'
                          value={value.address}
                          variant='outlined'
                          name='address'
                          label='Address'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        />
                        {validator.message(
                          'address',
                          value.address,
                          'required'
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          className='inputOutline'
                          fullWidth
                          placeholder='City'
                          value={value.city}
                          variant='outlined'
                          name='city'
                          label='City'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        />
                        {validator.message('city', value.city, 'required')}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          className='inputOutline'
                          fullWidth
                          placeholder='County'
                          value={value.county}
                          variant='outlined'
                          name='county'
                          label='County'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          className='inputOutline'
                          fullWidth
                          placeholder='Post code'
                          value={value.postcode}
                          variant='outlined'
                          name='postcode'
                          label='Post code'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        />
                        {validator.message(
                          'postcode',
                          value.postcode,
                          'required'
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          className='inputOutline'
                          fullWidth
                          placeholder='Mobile'
                          value={value.mobile}
                          variant='outlined'
                          name='mobile'
                          label='Mobile'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => changeHandler(e)}
                          onChange={(e) => changeHandler(e)}
                        />
                        {validator.message(
                          'mobile',
                          value.mobile,
                          'required|phone'
                        )}
                      </Grid>
                      {value.membership === 'Single' && (
                        <Grid item xs={12}>
                          <Grid className='formFooter'>
                            <Button
                              fullWidth
                              className='rBtn cBtnLarge cBtnTheme'
                              type='button'
                              onClick={convertToFamily}
                            >
                              Upgrade To Family Membership
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                      {value.membership === 'Family' && (
                        <>
                          <Grid item xs={12} className='text-center'>
                            <h5>Spouse Information</h5>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant='outlined'>
                              <InputLabel id='sp-title-label'>
                                Spouse Title
                              </InputLabel>
                              <Select
                                className='inputOutline'
                                id='spouseTitle'
                                labelId='sp-title-label'
                                name='spouseTitle'
                                value={value.spouseTitle}
                                label='Spouse Title'
                                placeholder='Title'
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                              >
                                <MenuItem value='Mr'>Mr</MenuItem>
                                <MenuItem value='Mrs'>Mrs</MenuItem>
                                <MenuItem value='Dr'>Dr</MenuItem>
                                <MenuItem value='Other'>Other</MenuItem>
                              </Select>
                              {validator.message(
                                'spouseTitle',
                                value.spouseTitle,
                                'required'
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              className='inputOutline'
                              fullWidth
                              placeholder='Spouse Name'
                              value={value.spouseName}
                              variant='outlined'
                              name='spouseName'
                              label='Spouse Name'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onBlur={(e) => changeHandler(e)}
                              onChange={(e) => changeHandler(e)}
                            />
                            {validator.message(
                              'spouseName',
                              value.spouseName,
                              'required'
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              className='inputOutline'
                              fullWidth
                              placeholder='Spouse E-mail'
                              value={value.spouseEmail}
                              variant='outlined'
                              name='spouseEmail'
                              label='E-mail'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onBlur={(e) => changeHandler(e)}
                              onChange={(e) => changeHandler(e)}
                            />
                            {validator.message(
                              'spouseEmail',
                              value.spouseEmail,
                              'required'
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              className='inputOutline'
                              fullWidth
                              placeholder='Spouse Mobile'
                              value={value.spouseMobile}
                              variant='outlined'
                              name='spouseMobile'
                              label='Mobile'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onBlur={(e) => changeHandler(e)}
                              onChange={(e) => changeHandler(e)}
                            />
                            {validator.message(
                              'spouseMobile',
                              value.spouseMobile,
                              'required'
                            )}
                          </Grid>
                          <Grid item xs={12} className='text-center'>
                            <h5>
                              Children {`  `}
                              <Select
                                variant='outlined'
                                className='inputOutline'
                                name='childCount'
                                value={value.childCount}
                                label='Count'
                                placeholder='childCount'
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                              >
                                <MenuItem value='0'>0</MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                              </Select>
                            </h5>
                          </Grid>
                          {Array.apply(null, { length: value.childCount }).map(
                            (e, i) => (
                              <Grid item xs={12} key={`childrenElement__${i}`}>
                                <Grid container spacing={3}>
                                  <Grid item xs={8}>
                                    <TextField
                                      className='inputOutline'
                                      fullWidth
                                      placeholder={`Child ${i + 1} Name`}
                                      value={value[`childName${i + 1}`]}
                                      variant='outlined'
                                      name={`childName${i + 1}`}
                                      label={`Child ${i + 1} Name`}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onBlur={(e) => changeHandler(e)}
                                      onChange={(e) => changeHandler(e)}
                                    />
                                    {validator.message(
                                      `childName${i + 1}`,
                                      value[`childName${i + 1}`],
                                      'required'
                                    )}
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      className='inputOutline'
                                      fullWidth
                                      placeholder='Year'
                                      value={value[`childYear${i + 1}`]}
                                      variant='outlined'
                                      name={`childYear${i + 1}`}
                                      label='Year of birth'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onBlur={(e) => changeHandler(e)}
                                      onChange={(e) => changeHandler(e)}
                                    />
                                    {validator.message(
                                      `childYear${i + 1}`,
                                      value[`childYear${i + 1}`],
                                      'required|numeric|max:4'
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          )}
                        </>
                      )}
                      {value.membership === 'Family' &&
                        parseInt(value.childCount) > 0 && (
                          <>
                            <Grid item xs={12}>
                              <h4>Child photo / video consent</h4>
                              <FormControl fullWidth variant='outlined'>
                                <FormControlLabel
                                  control={
                                    <GreenCheckbox
                                      name='childPhotoConcent'
                                      checked={value.childPhotoConcent}
                                      onChange={(e) => changeCheckboxHandler(e)}
                                    />
                                  }
                                  label={`I give permission to take photographs and / or video of my
                      child.`}
                                />
                                {validator.message(
                                  'childPhotoConcent',
                                  value.childPhotoConcent,
                                  'accepted'
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth variant='outlined'>
                                <FormControlLabel
                                  control={
                                    <GreenCheckbox
                                      name='childPhotoShareConcent'
                                      checked={value.childPhotoShareConcent}
                                      onChange={(e) => changeCheckboxHandler(e)}
                                    />
                                  }
                                  label={`I grant full rights to use the images resulting from the
                      photography/video filming, and any reproductions or
                      adaptations of the images for fundraising, publicity or other
                      purposes to help achieve MMA’s aims. This might include (but
                      is not limited to), the right to use them in their printed and
                      online publicity, social media, press releases and funding
                      applications.`}
                                />
                                {validator.message(
                                  'childPhotoShareConcent',
                                  value.childPhotoShareConcent,
                                  'accepted'
                                )}
                              </FormControl>
                            </Grid>
                          </>
                        )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <Grid className='formFooter'>
                        <Button
                          fullWidth
                          className='cBtn cBtnLarge cBtnTheme'
                          type='submit'
                          disabled={isLoading}
                        >
                          {isLoading
                            ? 'Please wait...'
                            : value.membership != initialMembership
                            ? `Pay Now £${productDetails.total}`
                            : 'Update'}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAccount;
