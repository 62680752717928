import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const PageTitle = (props) => {
  return (
    <section className='page-title'>
      <div className='page-title-container'>
        <div className='page-title-wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col col-xs-12'>
                <h2>{props.pageTitle}</h2>
                <ol className='breadcrumb'>
                  {props.parent ? (
                    <li>
                      <Link to={props.parent.link}>{props.parent.page}</Link>
                    </li>
                  ) : (
                    <li>
                      <Link to='/home'>Home</Link>
                    </li>
                  )}
                  <li>{props.pagesub}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
