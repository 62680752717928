import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api, apiToken } from "../../config";
import "./pay-status-style.scss";

const WomensDayTicketPaySuccess = (props) => {
  const ticketId = props.match.params.ticketId;
  const [loading, setLoading] = useState(true);
  const updateStatus = async () => {
    const transaction = await fetch(`${api}/collections/save/womensday`, {
      headers: {
        "Cockpit-Token": apiToken,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        data: {
          _id: ticketId,
          paymentStatus: true,
        },
      }),
    });
    const transactionSaved = await transaction.json();

    setLoading(false);
  };

  useEffect(() => {
    updateStatus();
  }, []);

  return (
    <div className="wrapper success-page">
      <h2>Payment Success</h2>
      <Link to="/" className="btn" disabled={loading}>
        {loading ? "Loading..." : "Done"}
      </Link>
    </div>
  );
};

export default WomensDayTicketPaySuccess;
